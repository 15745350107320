import { useState } from 'react'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash'
import Link from 'components/link'
import { Alert } from 'components/alerts'
import { SectionHeading } from 'components/headings'

const AlertMessage = ({ alert, name, storeUrl }) => {
  return (
    <li className="pbm">
      {storeUrl ? <Link url={storeUrl}>{name}</Link> : name}
      {` ${alert.message}`}
      {alert.fields?.base ? (
        <ul>
          {alert.fields.base.map((message) => (
            <li>
              <em>{message}</em>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  )
}

AlertMessage.propTypes = {
  storeUrl: PropTypes.string,
  name: PropTypes.string,
  alert: PropTypes.object,
}

AlertMessage.defaultProps = {
  storeUrl: '',
  name: '',
  alert: {},
}

const CartAlerts = ({
  items,
  orphanedReservations,
  unpurchasableReservations,
}) => {
  const [showAlert, setShowAlert] = useState(true)
  const itemsWithAlert = orderBy(items, ['createdAtS'], ['desc']).filter(
    (item) => item.stockAlert
  )

  if (
    (itemsWithAlert.length === 0 &&
      orphanedReservations.length === 0 &&
      unpurchasableReservations.length === 0) ||
    !showAlert
  )
    return null
  return (
    <Alert variant="info" onDismiss={() => setShowAlert(false)}>
      <SectionHeading style={{ marginTop: '3px' }} className="h4 bold">
        Important message about your cart
      </SectionHeading>
      <ul className="list-unstyled">
        {itemsWithAlert.map((item) => (
          <AlertMessage
            key={item.uuid}
            alert={item.stockAlert}
            name={item.name}
            storeUrl={item.storeUrl}
          />
        ))}
        {unpurchasableReservations.map((res) => (
          <AlertMessage
            key={res.token}
            alert={res.alert}
            name={res.regItem.title}
            storeUrl={res.regItem.offers?.find((o) => o.url)}
          />
        ))}
        {orphanedReservations.map((res) => (
          <AlertMessage
            key={res.token}
            alert={res.alert}
            name={res.regItem.title}
            storeUrl={res.regItem.offers?.find((o) => o.url)}
          />
        ))}
      </ul>
    </Alert>
  )
}

CartAlerts.defaultProps = {
  orphanedReservations: [],
  unpurchasableReservations: [],
}

CartAlerts.propTypes = {
  items: PropTypes.array.isRequired,
  orphanedReservations: PropTypes.array,
  unpurchasableReservations: PropTypes.array,
}

export default CartAlerts
