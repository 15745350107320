import PropTypes from 'prop-types'
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'
import { InfoButton } from 'components/buttons'
import { AddAnyStoreIcon } from 'components/icons'
import { DismissUI } from 'components/dismiss-ui'

export const AboutShopRegistryModal = ({ onClose }) => {
  return (
    <Modal show onHide={onClose}>
      <ModalBody>
        <div className="flex col center mtl pal">
          <AddAnyStoreIcon height={83} />
          <span className="text-center h4 pvm">
            Shop view organizes your unreserved items by store.
          </span>
          <span className="text-center">
            For the easiest checkout experience, we've grouped items into as few
            stores as possible.
          </span>
          <InfoButton
            className="mtl"
            onClick={() => onClose()}
            pill
            style={{ maxWidth: 350 }}
            block
          >
            Got It
          </InfoButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

AboutShopRegistryModal.propTypes = {
  onClose: PropTypes.func,
}

AboutShopRegistryModal.defaultProps = {
  onClose: () => {},
}

// TODO: Move dismissable HOC into its own file
export const DismissableAboutShopRegistryModal = () => (
  <DismissUI dismissDays={30} dismissId="about-shop-registry-modal">
    {(isDismissed, onDismiss) =>
      !isDismissed && <AboutShopRegistryModal onClose={onDismiss} />
    }
  </DismissUI>
)
