import { CSSProperties, forwardRef } from 'react'
import { startCase } from 'lodash'
import GiftCardImage from './ShopGiftCard.svg'
import css from './styles.scss'

interface PrintableGiftCardProps {
  dollarAmount: number | string
  giftCardCode: string
  giftGiverName?: string
  giftGiverMessage?: string
}

interface CSSStyles {
  [key: string]: CSSProperties
}

const PrintableGiftCard = forwardRef<HTMLDivElement, PrintableGiftCardProps>(
  ({ dollarAmount, giftCardCode, giftGiverMessage, giftGiverName }, ref) => {
    // CSS for this component must must flow through to the print screen function of the browser
    // Due to how the React app renders the styles in HTML, we are unable to import CSS from a styles file
    const styles: CSSStyles = {
      printSheet: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '800px',
        fontFamily: css.fontFamily,
        padding: '40px 0',
      },
      headerText: {
        fontWeight: 700,
        color: css.brandPurple,
        marginTop: '25px',
        marginBottom: 0,
        maxWidth: '500px',
      },
      imageContainer: { width: '400px', maxWidth: '100%' },
      contentText: {
        fontWeight: 500,
        color: css.brandPurple,
        maxWidth: '600px',
      },
      giftGiverName: {
        fontWeight: 700,
        color: css.brandPurple,
        marginTop: '25px',
        marginBottom: '25px',
      },
      instructionsText: { fontSize: '14px', marginTop: '5px', marginBottom: 0 },
    }

    const floatAmount = parseFloat(dollarAmount.toString())
    const displayAmount = Number.isInteger(floatAmount)
      ? floatAmount.toFixed(0)
      : floatAmount.toFixed(2)

    return (
      <div style={styles.printSheet} ref={ref}>
        <h2 style={styles.headerText}>
          Best day ever! You received a ${displayAmount} Babylist gift card.
        </h2>
        <h3 style={styles.contentText}>Gift card code: {giftCardCode}</h3>
        <div style={styles.imageContainer}>
          <GiftCardImage />
        </div>
        {giftGiverMessage && (
          <h3 style={styles.contentText}>"{giftGiverMessage}"</h3>
        )}
        {giftGiverName && (
          <h2 style={styles.giftGiverName}>From: {startCase(giftGiverName)}</h2>
        )}
        <p style={styles.instructionsText}>
          To redeem your gift card, log in or create an account and go to
          Registry &gt; Settings &gt; Gift Cards, Promos &amp; Gift Funds
        </p>
        <p style={styles.instructionsText}>
          Your balance will be automatically applied at checkout. Happy
          shopping!
        </p>
      </div>
    )
  }
)

export default PrintableGiftCard
