import { Component, StrictMode } from 'react'
import PropTypes from 'prop-types'
import { applyMiddleware, createStore } from 'redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import CartPage from 'cart/components/cart-page'
import { track, withContextualizedTracking } from 'lib/analytics'
import { Hydrate, QueryClientProvider, dehydrate } from '@tanstack/react-query'
import { queryClient } from 'src/queryClient'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import {
  OwnerMarkAsPurchasedModal,
  RedirectModal,
  ContactInfoModal,
  QuickViewModal,
} from 'registry/modals'
import { getCartWithRecommendations } from 'cart/api'
import {
  setAmazonCart,
  setCart,
  setProductRecommendations,
  setRegistry,
  setReservations,
  setUser,
} from '../actions'
import { giftGiverReducer } from '../reducers'
import ModalManager from '../components/modal-manager'
import {
  UPDATE_CART_SUMMARY,
  RECAPTCHA_V3_SITE_KEY,
} from '../../shared/constants'

const middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger())
}

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

const initReduxDevTools = () => {
  if (
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
  }
  return false
}

class GiftGiverReservationsApp extends Component {
  constructor(props) {
    super(props)
    // create the store
    this.store = createStoreWithMiddleware(
      giftGiverReducer,
      initReduxDevTools()
    )
    // bootstrap reducers
    this.store.dispatch(setAmazonCart(props.amazonCart))
    this.store.dispatch(setCart(props.cart))
    this.store.dispatch(setProductRecommendations(props.productRecommendations))
    this.store.dispatch(setReservations(props.reservations))
    this.store.dispatch(setRegistry(props.context.registry))
    this.store.dispatch(setUser(this.getMergedUserData()))
  }

  getChildContext() {
    const { context } = this.props
    return context
  }

  componentDidMount() {
    PubSub.subscribe(UPDATE_CART_SUMMARY, (data) => {
      getCartWithRecommendations().then((response) => {
        this.store.dispatch(setCart(response.cart))
        this.store.dispatch(setProductRecommendations(response.recommendations))
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props

    if (prevProps.currentUser !== currentUser) {
      this.store.dispatch(setUser(this.getMergedUserData()))
    }
  }

  getMergedUserData() {
    const { giftGiver, currentUser } = this.props

    if (currentUser && currentUser?.email === giftGiver?.email) {
      return {
        ...giftGiver,
        ...currentUser,
      }
    }

    return giftGiver
  }

  render() {
    const {
      disableBabylistOffer,
      maintenanceMode,
      orphanedReservations,
      processingReservations,
      showPurchaseConfirmationModal,
      showPurchaseCancelModal,
      hydrationData,
    } = this.props
    const { registry, isMobile } = this.props.context

    hydrationData?.forEach(({ key, data }) => {
      queryClient.setQueryData(key, data)
    })

    return (
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydrate(queryClient)}>
            <Provider store={this.store}>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
                <>
                  <CartPage
                    disableBabylistOffer={disableBabylistOffer}
                    maintenanceMode={maintenanceMode}
                    orphanedReservations={orphanedReservations}
                    processingReservations={processingReservations}
                    registry={registry}
                    showPurchaseConfirmationModal={
                      showPurchaseConfirmationModal
                    }
                    showPurchaseCancelModal={showPurchaseCancelModal}
                    isMobile={isMobile}
                  />
                  <ModalManager />
                  <RedirectModal />
                  <ContactInfoModal />
                  <OwnerMarkAsPurchasedModal />
                  <QuickViewModal />
                </>
              </GoogleReCaptchaProvider>
            </Provider>
          </Hydrate>
        </QueryClientProvider>
      </StrictMode>
    )
  }
}

GiftGiverReservationsApp.propTypes = {
  amazonCart: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  disableBabylistOffer: PropTypes.bool.isRequired,
  maintenanceMode: PropTypes.bool.isRequired,
  orphanedReservations: PropTypes.array.isRequired,
  processingReservations: PropTypes.array.isRequired,
  productRecommendations: PropTypes.array.isRequired,
  registry: PropTypes.object.isRequired,
  reservations: PropTypes.object.isRequired,
  showPurchaseCancelModal: PropTypes.bool.isRequired,
  showPurchaseConfirmationModal: PropTypes.bool.isRequired,
  giftGiver: PropTypes.object.isRequired,
}

GiftGiverReservationsApp.childContextTypes = {
  isBLOrderProcessing: PropTypes.bool,
  isMobile: PropTypes.bool,
  maintenanceModeMessage: PropTypes.string,
  registry: PropTypes.object,
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.CART,
})(withCurrentUser(GiftGiverReservationsApp))
