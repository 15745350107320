import { useRef } from 'react'
import Image from 'components/image'

const SAFARI_MEDIA = {
  videoUrl:
    '//images.babylist.com/video/upload/f_mp4,ac_none,fps_15,vc_h264:high/v1500398549/share-extension/video_skiphop_safari_no_tap.mp4',
  posterUrl:
    '//images.babylist.com/image/upload/v1500400565/share-extension/video-poster-safari.png',
  imageUrl:
    '//images.babylist.com/image/upload/v1500330139/share-extension/safari-screenshot.png',
}

const CHROME_MEDIA = {
  videoUrl:
    '//images.babylist.com/video/upload/f_mp4,ac_none,fps_15,vc_h264:high/v1500398202/share-extension/video_skiphop_chrome_no_tap.mp4',
  posterUrl:
    '//images.babylist.com/image/upload/v1500400589/share-extension/video-poster-chrome.png',
  imageUrl:
    '//images.babylist.com/image/upload/v1500330162/share-extension/chrome-screenshot.png',
}

const EXAMPLE_URL = 'http://www.skiphop.com/moby-ducky-bath-spout-cover'

interface HowItWorksProps {
  browser: 'chrome' | 'safari'
}

const HowItWorks = ({ browser }: HowItWorksProps) => {
  const { videoUrl, posterUrl, imageUrl } =
    browser === 'chrome' ? CHROME_MEDIA : SAFARI_MEDIA

  const videoRef = useRef<HTMLVideoElement>(null)

  const onVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }

  return (
    <div className="text-center mtxl">
      <div className="h3 text-bold">
        How to add items using the
        <br />
        Add to Babylist Button
      </div>
      <p className="small mbxl">
        {`Find an item from any store on the web. Then, tap the `}
        <strong>Add to Babylist Button</strong>
        {` to add it to your registry. Watch how it works:`}
      </p>
      <video
        ref={videoRef}
        poster={posterUrl}
        className="img-responsive center-block mbm phl"
        onClick={onVideoClick}
        controls
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
      <b className="h3 text-bold">Now try it out!</b>
      <p className="small mbxl">
        {`Tap the Skip Hop Bath Spout, below. Then tap your new `}
        <strong>Add to Babylist Button</strong>.
      </p>
      <a href={EXAMPLE_URL} target="_blank">
        <Image
          alt="Skip Hop Bath Spout"
          src={imageUrl}
          className="img-responsive center-block mbm phl"
        />
      </a>
    </div>
  )
}

export default HowItWorks
