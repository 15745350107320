import Link from 'components/link'
import { Button } from 'baby-design'
import css from './RegistryCta.scss'
import InsightSticker from '../../svg/InsightSticker.svg'
import ExpertSticker from '../../svg/ExpertSticker.svg'
import RegistrySticker from '../../svg/RegistrySticker.svg'
import { newRegistryPath } from 'lib/urls'

const RegistryCta = () => {
  return (
    <div className={css.RegistryCta}>
      <h2>We take care of growing families</h2>
      <div className={css.RegistryCta__modules}>
        <div className={css.RegistryCta__module}>
          <InsightSticker className={css.RegistryCta__module__icon} />
          <h3>Insights on your pregnancy</h3>
          <p>
            Get week-by-week updates and personalized info tailored to your due
            date.
          </p>
        </div>
        <div className={css.RegistryCta__module}>
          <ExpertSticker className={css.RegistryCta__module__icon} />
          <h3>Expert advice</h3>
          <p>
            Discover 100s of editorial guides that offer answers to burning
            questions like, "Should I buy a bottle warmer?"
          </p>
        </div>
        <div className={css.RegistryCta__module}>
          <RegistrySticker className={css.RegistryCta__module__icon} />
          <h3>The best universal baby registry</h3>
          <p>
            Add anything to your registry from any store, including favorites
            like Amazon, Target, Etsy, Nordstrom, H&M and Walmart.
          </p>
        </div>
      </div>
      <div className={css.RegistryCta__button}>
        <Link url={newRegistryPath}>
          <div className={css.RegistryCta__desktopOnly}>
            <Button
              variant="secondary"
              size="lg"
              className={css.RegistryCta__button}
            >
              Start Your Registry
            </Button>
          </div>
          <div className={css.RegistryCta__mobileOnly}>
            <Button
              variant="secondary"
              size="md"
              className={css.RegistryCta__button}
            >
              Start Your Registry
            </Button>
          </div>
        </Link>
      </div>
      <div className={css.RegistryCta__curve}></div>
    </div>
  )
}

export default RegistryCta
