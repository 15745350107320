import * as Urls from 'lib/urls'

import fetch from 'lib/fetch'
import querystring from 'querystring'
import { CASH_FUND_PRODUCTS_CATEGORY_ID } from 'shared/constants'

const POST = 'POST'
const GET = 'GET'
const PUT = 'PUT'
const DELETE = 'DELETE'
const PATCH = 'PATCH'

export const applyUserCredit = (data) => {
  return fetch(Urls.apiV3UserCreditPath, {
    body: JSON.stringify(data),
    method: POST,
  })
}

export const getUserCredit = () => {
  return fetch(Urls.apiV3UserCreditPath, {
    method: GET,
  })
}

export const getAddress = (uuid, token) => {
  return fetch(
    `${Urls.apiV3AddressesPath}/${uuid}${token ? `?token=${token}` : ''}`,
    {
      method: GET,
    }
  )
}

export const saveAddress = (data) => {
  return fetch(Urls.apiV3AddressesPath, {
    body: JSON.stringify(data),
    method: PUT,
  })
}

export const getCityStateByZip = (zip) => {
  const data = { zip }

  return fetch(Urls.apiV3ZipLookupsPath, {
    body: JSON.stringify(data),
    method: POST,
  })
}

export const getRegItemGroupGiftContributions = (registryId, regItemId) => {
  return fetch(Urls.apiV3RegItemGroupGiftsPath(registryId, regItemId), {
    method: GET,
  })
}

export const redeemGroupGifts = (data) => {
  return fetch(Urls.apiV3GroupGiftRedemptionsPath, {
    body: JSON.stringify(data),
    method: POST,
  })
}

export const saveCashFundPreferences = (data) => {
  return fetch(Urls.apiV3CashFundPreferencesPath, {
    body: JSON.stringify(data),
    method: POST,
  })
}

export const saveRegistry = (data) => {
  return fetch(Urls.apiV3RegistryPath(data.registry.id), {
    body: JSON.stringify(data),
    method: PUT,
  })
}

export const getRegistry = (id) => {
  return fetch(Urls.apiV3RegistryPath(id), {
    method: GET,
  })
}

export const getReservedRegItems = (id, isGuestView) => {
  return fetch(Urls.apiV3ReservedRegItemsPath(id, isGuestView), {
    method: GET,
  })
}

export const createRegistryCategory = (registryId, category) =>
  fetch(Urls.apiV3RegistryCategoriesPath(registryId), {
    method: POST,
    body: JSON.stringify({ category }),
  })

export const deleteRegistryCategory = (registryId, categoryId) => {
  return fetch(Urls.apiV3RegistryCategoryPath(registryId, categoryId), {
    method: DELETE,
  })
}
export const updateRegistryCategory = (
  registryId,
  { id, isPrivate, position, title }
) => {
  const params = {
    category: {
      isPrivate,
      position,
      title,
    },
  }

  return fetch(Urls.apiV3RegistryCategoryPath(registryId, id), {
    method: PUT,
    body: JSON.stringify(params),
  })
}

export const fetchRegItem = (registryId, regItemId) =>
  fetch(Urls.apiV3RegItemPath(regItemId, registryId), { method: GET })

export const saveRegItem = (registryId, regItemId, regItem) => {
  return fetch(Urls.apiV3RegItemPath(regItemId, registryId), {
    method: PATCH,
    body: JSON.stringify({ regItem }),
  })
}

/**
 * react-query friendy async function for fetching shop registry items
 *
 * @param {string} _key - react-query key.
 * @param {number} registryId - The id of a registry.
 * @returns {Promise} Promise from fetch call.
 */
export const fetchShopRegistryItems = async (_key, registryId) =>
  fetch(Urls.apiV3RegistriesShopPath(registryId), { method: GET })

// TODO: saveRegItem should replace this method
export const updateRegItem = ({ regItemId, registryId, fields }) =>
  fetch(Urls.apiV3RegItemPath(regItemId, registryId), {
    method: PUT,
    body: JSON.stringify(fields),
  })

export const deleteRegItem = (registryId, regItemId) =>
  fetch(Urls.apiV3RegItemPath(regItemId, registryId), { method: DELETE })

export const getCashFunds = () =>
  fetch(Urls.apiV3ProductsCategoryPath(CASH_FUND_PRODUCTS_CATEGORY_ID), {
    method: GET,
  }).then((resp) => ({
    cashFunds: resp.genericProducts.map((gp) => ({
      id: gp.id,
      path: `/gp/${gp.slug}/${gp.id}`,
      name: gp.name,
      imageUrl: gp.image.thumbnailUrl,
      brand: gp.brand,
    })),
  }))

export const getFeed = (params) =>
  fetch(`${Urls.apiV3FeedPath}?${querystring.stringify(params)}`, {
    method: GET,
  })

export const dismissFeedItem = ({ contentItemId }) =>
  fetch(Urls.apiV3FeedItemDismissalsPath, {
    method: POST,
    body: JSON.stringify({
      content_item_id: contentItemId,
    }),
  })

export const getRegistryRegItems = (registryId, params = {}) =>
  fetch(
    `${Urls.apiV3RegItemsPath(registryId)}?${querystring.stringify(params)}`,
    { method: GET }
  )
export const getRegistryCategories = (registryId, params = {}) =>
  fetch(
    `${Urls.apiV3RegistryCategoriesPath(registryId)}?${querystring.stringify(
      params
    )}`,
    { method: GET }
  )

export const getVisitorReservations = (registryId) =>
  fetch(Urls.apiV3RegistryVisitorReservationsPath(registryId), { method: GET })

export const getCatalogCategories = () =>
  fetch(Urls.apiV3CatalogCategoriesPath, { method: GET })

export const verifyPassword = (data) => {
  return fetch(Urls.apiV3PasswordVerificationPath, {
    body: JSON.stringify(data),
    method: POST,
  })
}
export const saveUser = (data) => {
  return fetch(Urls.apiV3UserPath, {
    body: JSON.stringify(data),
    method: PUT,
  })
}

export const deleteUser = (data) => {
  return fetch(Urls.apiV3UserPath, {
    method: DELETE,
    body: JSON.stringify(data),
  })
}

export const savePhoto = (data) => {
  return fetch(Urls.cloudinaryUrl, {
    body: JSON.stringify({
      upload_preset: 'unsigned_client_upload',
      file: data,
    }),
    method: POST,
    inflectKeys: false,
  })
}

export const saveUserEvent = (eventName, value = true) => {
  const method = value ? POST : DELETE
  return fetch(Urls.apiV3UserEventsPath, {
    body: JSON.stringify({ name: eventName }),
    method,
  })
}

export const saveInsertCardOrder = (insertCardOrder) => {
  let path = Urls.apiV3RegistryInsertCardOrdersPath(insertCardOrder.registryId)
  if (insertCardOrder.id) {
    path = `${path}/${insertCardOrder.id}`
  }

  return fetch(path, {
    body: JSON.stringify({ insertCardOrder }),
    method: insertCardOrder.id ? PUT : POST,
  })
}

export const cancelInsertCardOrder = (insertCardOrder) => {
  return fetch(
    Urls.apiV3RegistryInsertCardOrderPath(
      insertCardOrder.registryId,
      insertCardOrder.id
    ),
    {
      body: JSON.stringify({ insertCardOrder }),
      method: DELETE,
    }
  )
}

export const createRegItem = ({ registryId, fields }) =>
  fetch(Urls.apiV3RegItemsPath(registryId), {
    method: POST,
    body: JSON.stringify({ regItem: fields }),
  })

export const createReservation = (reservation, params = {}) =>
  fetch(Urls.apiV3ReservationsPath, {
    method: POST,
    body: JSON.stringify({ ...params, reservation }),
  })

export const updateAmazonCartItemQuantity = ({ id, quantity }) =>
  fetch(Urls.apiV3AmazonCartItemsPath(id), {
    method: PUT,
    body: JSON.stringify({ amazon_cart_item: { quantity } }),
  })

export const updateReservation = (reservationToken, reservation) =>
  fetch(Urls.apiV3ReservationPath(reservationToken), {
    method: PATCH,
    body: JSON.stringify({ reservation }),
  })

export const removeReservation = (reservationToken) =>
  fetch(Urls.unreserveReservationPath(reservationToken), {
    method: POST,
  })

export const recoverReservation = (email, regItemId) =>
  fetch(Urls.apiV3ReservationRecoveryPath(regItemId), {
    method: POST,
    body: JSON.stringify({ email }),
  })

export const submitInaccuracyReport = (
  reason,
  regItemId,
  url,
  suggestedPrice
) =>
  fetch(Urls.apiV3CreateInaccuracyReportPath, {
    method: POST,
    body: JSON.stringify({
      reason,
      regItemId,
      url,
      suggestedPrice,
    }),
  })

export const getUserOrders = () => {
  return fetch(Urls.apiV3UserOrdersPath, {
    method: GET,
  })
}

export const saveGiftGiverAddress = (data) => {
  return fetch(Urls.apiV3GiftGiverAddressesPath, {
    body: JSON.stringify(data),
    method: PUT,
  })
}

export const createTwoFactorProvision = () =>
  fetch(`${Urls.apiV3TwoFactorsPath}/new`, {
    method: GET,
  })

export const getTwoFactors = () =>
  fetch(Urls.apiV3TwoFactorsPath, {
    method: GET,
  })

export const createTwoFactor = (data) =>
  fetch(Urls.apiV3TwoFactorsPath, {
    body: JSON.stringify(data),
    method: POST,
  })

export const deleteTwoFactor = (id) =>
  fetch(`${Urls.apiV3TwoFactorsPath}/${id}`, {
    method: DELETE,
  })

export const revealReservations = (tokens) =>
  fetch(Urls.apiV3RevealReservationPath, {
    body: JSON.stringify({ tokens }),
    method: POST,
  })
