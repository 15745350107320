import {
  Aquarius,
  Aries,
  Cancer,
  Capricorn,
  Gemini,
  Leo,
  Libra,
  Pisces,
  Sagittarius,
  Scorpio,
  Taurus,
  Virgo,
} from './svg/zodiac'
import {
  Dog,
  Dragon,
  Goat,
  Horse,
  Monkey,
  Ox,
  Pig,
  Rabbit,
  Rat,
  Rooster,
  Snake,
  Tiger,
} from './svg/chinese_zodiac'
import {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
} from './svg/birth_stones'

const BirthStones = [
  { name: 'Garnet', component: January },
  { name: 'Amethyst', component: February },
  { name: 'Aquamarine', component: March },
  { name: 'Diamond', component: April },
  { name: 'Emerald', component: May },
  { name: 'Pearl', component: June },
  { name: 'Ruby', component: July },
  { name: 'Peridot', component: August },
  { name: 'Sapphire', component: September },
  { name: 'Opal', component: October },
  { name: 'Topaz', component: November },
  { name: 'Turquoise', component: December },
]

interface BirthStone {
  component: React.FC
  name: string
}

export function getBirthStone(date: Date): BirthStone {
  const month = date.getMonth() // getMonth() returns 0-11
  const { component, name } = BirthStones[month]

  return { component, name }
}

const ChineseZodiacAnimals = [
  { name: 'Rat', component: Rat },
  { name: 'Ox', component: Ox },
  { name: 'Tiger', component: Tiger },
  { name: 'Rabbit', component: Rabbit },
  { name: 'Dragon', component: Dragon },
  { name: 'Snake', component: Snake },
  { name: 'Horse', component: Horse },
  { name: 'Goat', component: Goat },
  { name: 'Monkey', component: Monkey },
  { name: 'Rooster', component: Rooster },
  { name: 'Dog', component: Dog },
  { name: 'Pig', component: Pig },
]

interface ChineseZodiac {
  component: React.FC
  name: string
}

export function getChineseZodiac(date: Date): ChineseZodiac {
  const year = date.getFullYear()
  const startYear = 1900 // The Chinese Zodiac cycle starts from 1900

  const animalIndex = (year - startYear) % 12
  const { component, name } = ChineseZodiacAnimals[animalIndex]

  return { component, name }
}

const ZodiacSigns = [
  { name: 'Capricorn', component: Capricorn, start: '01-01', end: '01-19' },
  { name: 'Aquarius', component: Aquarius, start: '01-20', end: '02-18' },
  { name: 'Pisces', component: Pisces, start: '02-19', end: '03-20' },
  { name: 'Aries', component: Aries, start: '03-21', end: '04-19' },
  { name: 'Taurus', component: Taurus, start: '04-20', end: '05-20' },
  { name: 'Gemini', component: Gemini, start: '05-21', end: '06-20' },
  { name: 'Cancer', component: Cancer, start: '06-21', end: '07-22' },
  { name: 'Leo', component: Leo, start: '07-23', end: '08-22' },
  { name: 'Virgo', component: Virgo, start: '08-23', end: '09-22' },
  { name: 'Libra', component: Libra, start: '09-23', end: '10-22' },
  { name: 'Scorpio', component: Scorpio, start: '10-23', end: '11-21' },
  { name: 'Sagittarius', component: Sagittarius, start: '11-22', end: '12-21' },
  { name: 'Capricorn', component: Capricorn, start: '12-22', end: '12-31' },
]

interface ZodiacSign {
  component: React.FC
  name: string
}

export function getZodiacSign(date: Date): ZodiacSign {
  for (const { name, component, start, end } of ZodiacSigns) {
    const [startMonth, startDay] = start.split('-').map(Number)
    const [endMonth, endDay] = end.split('-').map(Number)

    const startDate = new Date(date.getFullYear(), startMonth - 1, startDay)
    let endDate = new Date(date.getFullYear(), endMonth - 1, endDay)

    if (date >= startDate && date <= endDate) {
      return { component, name }
    }
  }

  return { component: Aries, name: 'Aries' }
}
