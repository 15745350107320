import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Media } from 'react-bootstrap'
import Tappable from 'react-tappable/lib/Tappable'
import { map, range } from 'lodash'
import classNames from 'classnames'
import Image from 'components/image'
import Link from 'components/link'
import { addOrReplaceImageTransform } from 'lib/cloudinary'
import { helloBabyPostPath } from 'lib/urls'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import ProductCard from '../product-card'
import ProductCardGhost from '../product-card-ghost'
import ProductCardV2 from '../ProductCardV2'
import css from './product-grid.scss'

const ProductGrid = ({ activeSlugSet, className, productCards, guides }) => {
  // TODO: Remove this feature flag once the PDP redesign is fully launched.
  const { flagValue: variationValue } = useFeatureFlag(
    '24c_ex_shop_pdp_redesign_rollout',
    'control'
  )
  const inSaleBadgingFeature =
    useFeatureFlag('24d_ff_shop_sale_ui_web', false).flagValue === true
  const isInPDPRedesignFeature = variationValue !== 'control'

  const NUM_GHOSTS = 6
  const renderGhostGrid = () => (
    <>
      {map(range(NUM_GHOSTS), (idx) => (
        <div className={css['ProductGrid__grid-item--product']} key={idx}>
          <ProductCardGhost />
        </div>
      ))}
    </>
  )

  const renderGrid = () => {
    const gridItems = map(productCards, (card, idx) => {
      const { productBadge, ...otherCardProps } = card
      const badgeComponent = productBadge && (
        <ProductCard.Badge
          alt={productBadge.title}
          imgUrl={productBadge.imageUrl}
        />
      )
      return (
        <div
          className={css['ProductGrid__grid-item--product']}
          key={`${card.title}-${idx}`}
        >
          <ProductCardV2
            {...otherCardProps}
            __forcePageLoad={isInPDPRedesignFeature}
            inSaleBadgingFeature={inSaleBadgingFeature}
            position={idx + 1}
            slugSet={activeSlugSet}
          />
        </div>
      )
    })
    return gridItems
  }

  const renderGuides = () => {
    if (productCards?.length >= 7 && guides && guides.length > 0) {
      const guide = guides[0] // only show the first guide for now
      return (
        <div className={css['ProductGrid__grid-item--guide']} key={guide.title}>
          <GuideCard className={css.guideCard} post={guide} />
        </div>
      )
    }
  }

  return (
    <div
      className={classNames(
        css.ProductGrid,
        css['ProductGrid--3-col'],
        className
      )}
    >
      {productCards ? renderGrid() : renderGhostGrid()}
      {productCards ? renderGuides() : null}
    </div>
  )
}

ProductGrid.propTypes = {
  className: PropTypes.string,
  guides: PropTypes.array,
  productCards: PropTypes.array,
  activeSlugSet: PropTypes.array,
}

ProductGrid.defaultProps = {
  className: '',
  guides: [],
  productCards: [],
  activeSlugSet: [],
}

export const GuideCard = ({ className, post }) => (
  <Tappable
    className="text-body"
    component={Link}
    url={helloBabyPostPath(post.slug)}
  >
    <Media className={classNames(className, 'position-rel')}>
      <div className="hover-overlay hover-darken">
        {/* width % attempts to closely match the width of the product card */}
        <Media.Left className="pan" style={{ width: '44%' }}>
          <Image
            alt={post.title}
            src={addOrReplaceImageTransform(
              post.featuredImage.url,
              'c_fill,g_auto:face,h_900,w_500'
            )}
            style={{ width: '100%' }}
          />
        </Media.Left>
        <Media.Body className="bbs brs bts mbm">
          <div className="center-y pal">
            <div className={classNames('h6', css.guideCardLabel)}>Guide</div>
            <div className={`media-heading ${css.guideCardTitle}`}>
              {post.title}
            </div>
            <p className={css.guideCardTeaser}>{post.teaser}</p>
            {/* Make this look like a link, but in fact the entire card is clickable */}
            <p className="h7 text-brand-info text-uppercase">Read More</p>
          </div>
        </Media.Body>
      </div>
    </Media>
  </Tappable>
)

GuideCard.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
}

GuideCard.defaultProps = {
  className: '',
}

export default ProductGrid
