import { Component } from 'react'
import PropTypes from 'prop-types'

import * as BLConstants from 'shared/constants'
import EUBlockedContent from 'shared/EUBlockedContent/EUBlockedContent'
import PregnancyEmailLayout from './pregnancy-email-layout'
import EmailSignupForm from 'components/email-signup-form'

import * as blUrls from 'lib/urls'
import css from './pregnancy-email-signup.scss'

export class PregnancyEmailSignup extends Component {
  static propTypes = {
    emailUrlsByWeek: PropTypes.object.isRequired,
    isEuIp: PropTypes.bool.isRequired,
  }

  state = {
    submitting: false,
    submitted: false,
    fields: {},
    errors: {},
  }

  render() {
    const { emailUrlsByWeek, isEuIp } = this.props
    return (
      <PregnancyEmailLayout
        heroImage="https://images.babylist.com/image/upload/f_auto,q_auto,c_scale,h_1280/v1574124023/emailsignup_landingpage_final_tilysb.jpg"
        heroText={this.renderHeroText()}
      >
        <div className={css.contentBody}>
          <div className={css.teaserText}>
            Weekly pregnancy and new baby updates, plus the latest parenting
            news delivered to your inbox. It's like tips and advice from your
            BFF and OB rolled into one!
          </div>
          {isEuIp ? (
            <EUBlockedContent className="bas pal" />
          ) : (
            <div>
              <EmailSignupForm
                emailUrlsByWeek={emailUrlsByWeek}
                emailType={BLConstants.EMAIL_USER_PREGNANCY}
                userSource={BLConstants.USER_SOURCE_EMAIL_PREGNANCY_DIRECT}
              />
              <p className={css.psText}>
                <i className="fa fa-lock" /> Babylist will keep your information
                private and secure.
              </p>
            </div>
          )}
        </div>
        <p className="h7 mtxl mbn text-muted">
          As a registered member of Babylist you'll receive newsletters
          personalized for your pregnancy or the age of your child. You may
          modify your selections at any time after you sign up by updating your
          account. See our <a href={blUrls.privacyPolicyPath}>Privacy Policy</a>
          .
        </p>
      </PregnancyEmailLayout>
    )
  }

  renderHeroText() {
    return (
      <span>
        From BFP to birth and beyond, <br className="visible-lg" /> we've got
        you covered.
      </span>
    )
  }
}

export default PregnancyEmailSignup
