import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { bowserFlags } from 'lib/bowser'
import { Media } from 'react-bootstrap'
import Image from 'components/image'
import Offer from 'shared/offer'
import { getLoggedIn } from 'cart/reducers'
import Link from 'components/link'
import { LinkButton } from 'components/buttons'
import InStockAlertButton from 'store/components/in-stock-alert-button'
import { track, useTracking } from 'lib/analytics'
import { ItemShape } from 'cart/lib/prop-types'
import { getGiftCardProductId } from 'cart/lib'
import BundleComponentsList from 'shared/product-list/bundle-components-list'
import GiftAmountSelect from 'cart/components/gift-amount-select'
import css from 'cart/components/gift-amount-select/gift-amount-select.scss'
import { ProductCallouts } from 'components/ProductCallouts/ProductCallouts'
import { PriceTag, QuantityPicker } from 'baby-design'
import { getPriceTagPropsFromPriceDetails } from 'src/routes/(shop)/lib/productUtils'
import { CartItemsAPI } from 'src/routes/(shop)/api/cartItems'
import { numberToCurrency } from 'lib/money'

const BLCartOrderItem = ({
  cartItem,
  onRemoveCartItem,
  useRegistryDiscount,
  isOutOfStock,
  isLoggedIn,
  inSaleUIFeature,
}) => {
  const tracker = useTracking()
  const [localQuantity, setLocalQuantity] = useState(cartItem.quantity)
  const { mutate: updateCartItem } = CartItemsAPI.useUpdateMutation()

  const handleUpdateCartItem = (updatedCartItem) => {
    const newQuantity = updatedCartItem.quantity
    setLocalQuantity(newQuantity)

    tracker.trackEvent({
      event: track.cartModified,
      eventType: track.EventType.ITEM_QUANTITY_UPDATED,
      cartItem: updatedCartItem,
    })

    updateCartItem(updatedCartItem, {
      onError: () => {
        setLocalQuantity(cartItem.quantity)
      },
    })
  }

  const {
    bundleComponents,
    callouts,
    giftCardOptions,
    productId,
    imageUrl,
    isGiftCard,
    isEligibleForRegistryDiscount,
    name,
    price,
    quantity,
    quantityLimit,
    registryDiscountPrice,
    extendedDeliveryTimeMessage,
    storeUrl,
  } = cartItem

  const bundleComponentList =
    bundleComponents?.length > 0 ? (
      <BundleComponentsList
        className="pbm"
        products={bundleComponents}
        showShippingMsg={!isOutOfStock}
      />
    ) : null

  const { msrp, currentPrice, onSale } = getPriceTagPropsFromPriceDetails(
    cartItem.priceDetails
  )

  const usingRegistryDiscount = useRegistryDiscount && registryDiscountPrice

  const priceTagProps = {
    currentPrice: usingRegistryDiscount
      ? numberToCurrency(registryDiscountPrice, { unit: '' })
      : currentPrice,
    currentlyInActiveSale: usingRegistryDiscount ? false : onSale,
    msrp,
  }

  const offerComponent = inSaleUIFeature ? (
    <div className="mtl">
      <PriceTag {...priceTagProps} />
    </div>
  ) : (
    <div className={classNames({ mtm: bowserFlags.mobile })}>
      <div className="mvm mtl">
        <Offer
          discountedPrice={useRegistryDiscount ? registryDiscountPrice : null}
          isLink={false}
          price={price}
          storeIconName="babylist"
          storeName="Babylist"
          style={{ padding: 0 }}
          wrapperClassName="cart-order-item-offer-wrapper"
        />
      </div>
    </div>
  )

  const removeComponent = (
    <span className="h7 mbl mhl">
      <LinkButton
        className="xsmall pan"
        onClick={() => onRemoveCartItem(cartItem)}
      >
        Remove
      </LinkButton>
    </span>
  )

  const notEligibleForRegistryDiscountComp =
    useRegistryDiscount && !isEligibleForRegistryDiscount ? (
      <p className="h7 mvm text-muted">Not eligible for discount.</p>
    ) : null

  return (
    <div>
      <Media className="media-order-item pvl" id={productId}>
        <Media.Left aria-hidden="true" className="prl">
          <Link url={storeUrl}>
            <Image
              height={100}
              role="presentation"
              src={imageUrl}
              width={100}
            />
          </Link>
        </Media.Left>
        <Media.Body>
          <Link className="media-heading h5 block" url={storeUrl}>
            {name}
          </Link>
          <ProductCallouts callouts={callouts} textSize={13} />
          <div className="mtm">
            <div className="change-quantity-wrapper">
              {isGiftCard ? (
                <GiftAmountSelect
                  defaultGiftAmount={price}
                  options={giftCardOptions.map((gc) => gc.price)}
                  size="small"
                  wrapperClassName={css.inlineGiftAmountGroup}
                  onChange={(e) =>
                    handleUpdateCartItem({
                      ...cartItem,
                      productId: getGiftCardProductId(
                        e.target.value,
                        giftCardOptions
                      ),
                      price: e.target.value,
                    })
                  }
                />
              ) : (
                <QuantityPicker
                  max={Math.max(quantity, quantityLimit)}
                  size="sm"
                  type="cart"
                  value={localQuantity}
                  variant="fill"
                  onMinusClick={() =>
                    handleUpdateCartItem({
                      ...cartItem,
                      quantity: localQuantity - 1,
                    })
                  }
                  onPlusClick={() =>
                    handleUpdateCartItem({
                      ...cartItem,
                      quantity: localQuantity + 1,
                    })
                  }
                  onTrashClick={() => onRemoveCartItem(cartItem)}
                />
              )}
            </div>
            {isOutOfStock && isLoggedIn && (
              <InStockAlertButton
                buttonStyle={{
                  style: { maxWidth: '200px', marginBottom: '20px' },
                }}
                productId={productId}
              />
            )}
            <div className="hidden-xs">
              {offerComponent}
              {bundleComponentList}
              {isGiftCard && removeComponent}
              {extendedDeliveryTimeMessage && (
                <p className="h7 mtm text-muted">
                  {extendedDeliveryTimeMessage}
                </p>
              )}
              {notEligibleForRegistryDiscountComp}
            </div>
          </div>
        </Media.Body>
      </Media>

      <div className="visible-xs">
        {offerComponent}
        {bundleComponentList}
        <div className="mvl">
          {isGiftCard && removeComponent}
          {extendedDeliveryTimeMessage && (
            <p className="h7 mtm text-muted">{extendedDeliveryTimeMessage}</p>
          )}
          {notEligibleForRegistryDiscountComp}
        </div>
      </div>
    </div>
  )
}

BLCartOrderItem.propTypes = {
  cartItem: PropTypes.shape(ItemShape).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  onRemoveCartItem: PropTypes.func.isRequired,
  onUpdateItemSuccess: PropTypes.func.isRequired,
  useRegistryDiscount: PropTypes.bool,
  isOutOfStock: PropTypes.bool,
  extendedDeliveryTimeMessage: PropTypes.string,
}

BLCartOrderItem.defaultProps = {
  isOutOfStock: false,
  useRegistryDiscount: false,
}

const mapStateToProps = (state) => ({
  isLoggedIn: getLoggedIn(state),
})

export default connect(mapStateToProps, {})(BLCartOrderItem)
