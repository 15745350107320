import React from 'react'

const ShopGiftCard: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 514 302"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="76"
      y="30.196"
      width="361.15"
      height="225.895"
      rx="14"
      fill="#F9F6F3"
      stroke="#6E3264"
      stroke-width="2"
    />
    <g clip-path="url(#clip0_959_403)">
      <path
        d="M187.928 146.41L181.961 145.032C178.352 144.177 176.6 142.467 176.6 139.805C176.6 136.821 179.318 134.812 183.37 134.812C188.644 134.812 191.746 138.003 193.242 139.541L193.452 139.753L197.725 135.288L197.538 135.093C193.586 131.03 188.952 128.97 183.766 128.97C180.221 128.97 176.92 129.969 174.475 131.776C171.745 133.796 170.302 136.654 170.302 140.028C170.302 145.732 173.975 149.451 181.222 151.081L186.88 152.378C190.541 153.227 192.474 155.006 192.474 157.531C192.474 160.945 189.395 162.982 184.231 162.982C180.046 162.982 175.843 160.882 172.985 157.359L172.781 157.106L168.584 161.41L168.736 161.605C171.437 165.06 176.943 168.744 184.313 168.744C188.568 168.744 192.2 167.63 194.814 165.53C197.41 163.441 198.784 160.572 198.784 157.227C198.784 151.677 195.134 148.034 187.945 146.404L187.928 146.41Z"
        fill="#723364"
      />
      <path
        d="M287.94 131.49C287.951 148.975 287.957 168.273 287.94 185.758H294.413L294.395 164.555C299.704 168.945 305.63 170.557 312.26 169.145C317.138 168.112 321.085 165.519 324.048 161.553C329.997 153.588 329.106 142.571 322.01 135.598C314.979 128.689 303.092 127.541 294.401 134.933C294.401 134.944 294.418 131.478 294.413 131.484H287.945L287.94 131.49ZM322.063 149.686C322.092 157.439 315.741 163.734 307.9 163.74C300.088 163.74 293.714 157.502 293.697 149.818C293.679 142.06 300.007 135.782 307.865 135.77C315.689 135.759 322.039 141.985 322.069 149.686H322.063Z"
        fill="#723364"
      />
      <path
        d="M263.282 130.124C252.035 130.124 242.919 139.11 242.919 150.197C242.919 161.284 252.035 170.27 263.282 170.27C274.528 170.27 283.644 161.284 283.644 150.197C283.644 139.11 274.528 130.124 263.282 130.124ZM263.299 164.182C255.475 164.193 249.125 157.967 249.096 150.266C249.066 142.513 255.417 136.218 263.258 136.212C271.07 136.212 277.444 142.45 277.462 150.134C277.479 157.892 271.152 164.17 263.293 164.182H263.299Z"
        fill="#723364"
      />
      <path
        d="M221.841 129.028C218.331 129.028 213.098 130.583 210.1 134.967V112.627L203.691 117.247V168.267H210.1V147.89C210.1 139.271 214.786 135.024 221.492 134.806C229.188 134.554 232.733 139.667 232.965 147.442L233.14 168.267H239.299L239.066 147.902C239.066 134.405 230.759 129.033 221.847 129.033L221.841 129.028Z"
        fill="#723364"
      />
      <path
        d="M216.195 117.247C216.195 117.247 216.229 117.252 216.241 117.252L216.252 117.247H216.195Z"
        fill="#723364"
      />
      <path
        d="M344.008 159.16C342.803 158.305 341.488 158.219 340.143 158.833C339.299 159.218 338.758 159.866 338.379 160.658C338.001 159.866 337.459 159.218 336.615 158.833C336.458 158.764 336.307 158.701 336.15 158.65C336.097 158.633 336.045 158.621 335.993 158.604C335.894 158.575 335.795 158.546 335.696 158.523C335.632 158.512 335.568 158.501 335.504 158.489C335.416 158.472 335.323 158.46 335.236 158.455C335.172 158.449 335.108 158.443 335.038 158.437C335.015 158.437 334.986 158.437 334.962 158.437C334.962 158.437 334.956 158.437 334.951 158.443C334.188 158.432 333.449 158.661 332.744 159.16C331.557 160.004 331.103 161.192 331.283 162.621C331.516 164.463 332.547 165.857 333.885 167.068C335.218 168.273 336.749 169.174 338.379 169.926C340.009 169.174 341.54 168.273 342.873 167.068C342.885 167.057 342.896 167.045 342.908 167.034C342.99 166.965 343.065 166.884 343.141 166.81C343.217 166.735 343.298 166.661 343.374 166.586C343.455 166.5 343.537 166.414 343.618 166.328C343.688 166.253 343.758 166.179 343.828 166.104C343.903 166.012 343.979 165.92 344.049 165.829C344.113 165.754 344.177 165.674 344.235 165.599C344.305 165.501 344.369 165.404 344.439 165.306C344.491 165.226 344.55 165.151 344.602 165.065C344.666 164.962 344.724 164.853 344.782 164.744C344.823 164.664 344.876 164.589 344.916 164.503C344.975 164.388 345.015 164.273 345.068 164.153C345.103 164.073 345.143 163.992 345.172 163.906C345.219 163.774 345.26 163.636 345.295 163.499C345.318 163.424 345.341 163.355 345.365 163.275C345.417 163.057 345.458 162.839 345.487 162.609C345.667 161.18 345.213 159.993 344.026 159.149L344.008 159.16Z"
        fill="#723364"
      />
      <path
        d="M248.31 116.644C248.31 116.644 248.31 117.993 248.31 117.987H250.842C250.842 111.17 250.836 110.688 250.842 103.87H248.31L248.316 105.093C246.243 103.383 243.932 102.751 241.348 103.302C239.444 103.704 237.908 104.714 236.749 106.263C234.427 109.368 234.776 113.666 237.547 116.386C240.288 119.077 244.922 119.525 248.316 116.644H248.31ZM237.523 110.894C237.512 107.87 239.986 105.414 243.048 105.414C246.098 105.414 248.578 107.847 248.589 110.843C248.595 113.867 246.127 116.317 243.065 116.323C240.015 116.323 237.535 113.901 237.523 110.894Z"
        fill="#723364"
      />
      <path
        d="M255.598 117.987C255.598 117.987 255.598 116.638 255.598 116.644C258.986 119.525 263.625 119.077 266.367 116.386C269.132 113.666 269.481 109.373 267.164 106.263C266.012 104.72 264.469 103.71 262.571 103.302C259.987 102.751 257.676 103.383 255.604 105.093V96.8235H253.077C253.083 103.641 253.077 111.17 253.077 117.987H255.598ZM260.854 105.414C263.916 105.414 266.39 107.87 266.378 110.894C266.367 113.895 263.887 116.323 260.837 116.323C257.775 116.323 255.307 113.872 255.313 110.843C255.318 107.847 257.804 105.414 260.854 105.414Z"
        fill="#723364"
      />
      <path
        d="M218.378 117.987H220.904C220.904 117.987 220.904 116.638 220.904 116.644C224.292 119.525 228.931 119.077 231.673 116.386C234.438 113.666 234.787 109.373 232.471 106.263C231.318 104.72 229.775 103.71 227.872 103.302C225.287 102.751 222.976 103.383 220.904 105.093V96.8235H218.378C218.384 103.641 218.378 111.17 218.378 117.987ZM226.166 105.414C229.228 105.414 231.702 107.87 231.691 110.894C231.679 113.895 229.199 116.323 226.149 116.323C223.087 116.323 220.619 113.872 220.625 110.843C220.631 107.847 223.116 105.414 226.166 105.414Z"
        fill="#723364"
      />
      <path
        d="M278.096 110.091C277.421 111.571 276.74 113.052 276.03 114.607C275.552 113.574 275.11 112.616 274.673 111.657C273.521 109.15 272.258 106.389 271.105 103.882H268.387C270.36 108.1 272.671 113.04 274.662 117.252L270.948 125.04H273.719C276.402 119.295 279.843 112.053 282.532 106.315C282.904 105.523 283.277 104.72 283.667 103.882H280.908C280.029 105.839 278.987 108.134 278.096 110.085V110.091Z"
        fill="#723364"
      />
      <path
        d="M311.998 111.807C311.998 112.788 311.969 113.775 312.039 114.75C312.149 116.34 312.871 117.585 314.414 118.234C315.788 118.808 317.394 118.658 318.803 118.285V116.036C318.18 116.214 317.272 116.34 316.626 116.3C315.403 116.225 314.786 115.68 314.6 114.492C314.565 114.268 314.554 114.033 314.554 113.804C314.554 111.365 314.554 108.61 314.542 106.171H318.803C318.803 106.171 318.803 103.853 318.803 103.847H314.548V99.9739C313.71 100.393 312.79 100.84 311.998 101.225V103.842C311.148 103.83 309.699 103.842 308.849 103.83V106.16H312.004C311.992 107.922 311.992 110.028 312.004 111.789L311.998 111.807Z"
        fill="#723364"
      />
      <path
        d="M287.887 96.8235H285.402V117.998H287.887V96.8235Z"
        fill="#723364"
      />
      <path
        d="M293.388 103.859H290.903V117.998H293.388V103.859Z"
        fill="#723364"
      />
      <path
        d="M292.142 100.858C293.103 100.858 293.883 100.089 293.883 99.1418C293.883 98.1949 293.103 97.426 292.142 97.426C291.182 97.426 290.402 98.1949 290.402 99.1418C290.402 100.089 291.182 100.858 292.142 100.858Z"
        fill="#723364"
      />
      <path
        d="M301.77 116.374C300.141 116.374 298.499 115.554 297.381 114.182L297.3 114.085L295.664 115.766L295.722 115.841C296.776 117.189 298.924 118.624 301.794 118.624C303.453 118.624 304.867 118.188 305.886 117.367C306.899 116.552 307.434 115.433 307.434 114.131C307.434 111.967 306.014 110.544 303.208 109.913L300.88 109.379C299.471 109.046 298.79 108.381 298.79 107.342C298.79 106.177 299.85 105.397 301.427 105.397C303.482 105.397 304.693 106.642 305.275 107.239L305.356 107.325L307.021 105.586L306.945 105.512C305.403 103.928 303.598 103.124 301.573 103.124C300.187 103.124 298.901 103.515 297.952 104.22C296.887 105.007 296.322 106.12 296.322 107.44C296.322 109.66 297.754 111.112 300.583 111.749L302.789 112.254C304.215 112.587 304.972 113.281 304.972 114.263C304.972 115.594 303.773 116.386 301.759 116.386L301.77 116.374Z"
        fill="#723364"
      />
    </g>
    <path
      d="M428.002 100.965C419.266 104.014 414.533 113.51 416.955 122.58C417.149 123.311 417.916 123.727 418.615 123.483L425.698 121.01C426.207 120.833 426.499 120.3 426.383 119.762C425.507 115.851 427.592 111.843 431.342 110.534C434.883 109.298 438.762 110.867 440.656 114.075C441.136 114.896 442.107 115.283 442.978 114.978L449.526 112.693C450.134 112.481 450.429 111.778 450.164 111.166C446.472 102.456 436.783 97.8995 428.002 100.965Z"
      fill="#A0DCFF"
      stroke="#6E3264"
      stroke-width="2"
    />
    <path
      d="M112.5 267C118.299 267 123 262.299 123 256.5C123 250.701 118.299 246 112.5 246C106.701 246 102 250.701 102 256.5C102 262.299 106.701 267 112.5 267Z"
      fill="#FF6D4E"
      stroke="#6E3264"
      stroke-width="2"
    />
    <path
      d="M37.4112 264.243L35.307 284.175C35.1502 285.672 36.6237 286.832 37.9613 286.261L56.4073 278.385C57.764 277.806 57.9283 275.899 56.6995 274.993L40.3481 262.942C39.1696 262.073 37.5617 262.784 37.4112 264.243Z"
      fill="#FFD43E"
      stroke="#6E3264"
      stroke-width="2"
    />
    <path
      d="M395.916 27.3513C395.909 27.3339 395.902 27.3165 395.896 27.2991C394.54 23.8227 396.236 19.9148 399.684 18.5706C403.131 17.2263 407.025 18.9547 408.381 22.4311C408.387 22.4486 408.394 22.466 408.401 22.4834C408.418 22.4765 408.435 22.4697 408.453 22.4629C411.929 21.1074 415.837 22.8035 417.181 26.2511C418.526 29.6988 416.797 33.5924 413.321 34.9479C413.303 34.9547 413.286 34.9614 413.269 34.9681C413.275 34.9854 413.282 35.0028 413.289 35.0202C414.645 38.4966 412.949 42.4045 409.501 43.7488C406.053 45.093 402.16 43.3646 400.804 39.8882C400.797 39.8708 400.791 39.8533 400.784 39.8359C400.767 39.8428 400.749 39.8497 400.732 39.8565C397.255 41.2119 393.348 39.5159 392.003 36.0682C390.659 32.6206 392.387 28.7269 395.864 27.3714C395.881 27.3646 395.899 27.3579 395.916 27.3513Z"
      fill="#FED7FF"
      stroke="#6E3264"
      stroke-width="2"
    />
    <path
      d="M476 78C481.523 78 486 73.299 486 67.5C486 61.701 481.523 57 476 57C470.477 57 466 61.701 466 67.5C466 73.299 470.477 78 476 78Z"
      fill="#56BB6C"
      stroke="#6E3264"
      stroke-width="2"
    />
    <path
      d="M45.8523 189.136C40.9184 187.45 35.4101 190.482 33.5557 195.908L29.9426 206.479C29.5733 207.559 30.1303 208.717 31.1696 209.073L45.096 213.833C46.2338 214.222 47.4759 213.59 47.876 212.42L51.4315 202.018C53.2968 196.596 50.7971 190.826 45.8523 189.136Z"
      fill="#A0DCFF"
      stroke="#6E3264"
      stroke-width="2"
    />
    <defs>
      <clipPath id="clip0_959_403">
        <rect
          width="176.943"
          height="88.9348"
          fill="white"
          transform="translate(168.567 96.8235)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default ShopGiftCard
