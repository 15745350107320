import * as blUrls from 'lib/urls'

import { InfoAlert } from 'components/alerts'
import { Outlet, useLocation } from 'react-router-dom-v5-compat'
import { useEffect } from 'react'

interface ContainerPropTypes {
  showDesktopAlert: boolean
}

const Container = ({ showDesktopAlert }: ContainerPropTypes) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <div className="mtxl">
      {showDesktopAlert ? (
        <InfoAlert>
          <p>
            These are instructions to install the share extension on your iPhone
            or iPad.
          </p>
        </InfoAlert>
      ) : null}
      <div className="center-block" style={{ maxWidth: 400 }}>
        <Outlet />
        <div className="text-center mvxl">
          <a className="small text-uppercase" href={blUrls.contactUsPath}>
            Need Help?
          </a>
        </div>
      </div>
    </div>
  )
}

export default Container
