import Router from 'shared/universal-router'
import { Route, Routes } from 'react-router-dom-v5-compat'
import * as blUrls from 'lib/urls'

import Container from './container'
import Instructions from './instructions'
import HowItWorks from './how-it-works'

interface ShareExtensionProps {
  isSupportedDevice: boolean
  browser: 'chrome' | 'safari'
  path: string
}

const ShareExtension = ({
  isSupportedDevice,
  browser,
  path,
}: ShareExtensionProps) => {
  return (
    <>
      {/* @ts-ignore */}
      <Router location={{ pathname: path }} useV6Router>
        <Routes>
          <Route
            path={blUrls.shareExtensionPath}
            element={<Container showDesktopAlert={!isSupportedDevice} />}
          >
            <Route index element={<Instructions browser={browser} />} />
            <Route
              path="how-it-works"
              element={<HowItWorks browser={browser} />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  )
}

export default ShareExtension
