import React from 'react'
import { map } from 'lodash'
import { InfoButton } from 'components/buttons'
import Image from 'components/image'
import * as blUrls from 'lib/urls'
import { useNavigate } from 'react-router-dom-v5-compat'

const RightArrow = () => (
  <i title="right" className="fa fa-long-arrow-right fa-lg mhs text-primary" />
)

const IOS_SHARE_ICON_IMG =
  'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2218%22%20viewBox%3D%220%200%2013%2018%22%3E%3Cpath%20fill%3D%22%23167EFB%22%20fill-rule%3D%22evenodd%22%20d%3D%22M6.158%201.358l-2.07%202.08a.156.156%200%200%201-.226-.003l-.258-.273a.168.168%200%200%201-.003-.232L6.471.046a.155.155%200%200%201%20.224.004l.258.273c.026.027.042.06.047.095l2.578%202.56a.166.166%200%200%201-.004.229l-.283.268a.154.154%200%200%201-.222.004l-2.227-2.21v10.28h-.684V1.357zM.684%205.776V17.32h11.632V5.776H8.21v-.68H13V18H0V5.096h4.79v.68H.683z%22%2F%3E%3C%2Fsvg%3E'

interface Step {
  beforeText?: React.ReactElement
  imageUrl?: string
  afterText?: React.ReactElement
}

const SHARED_STEPS: Array<Step> = [
  {
    beforeText: (
      <span>
        {`Swipe `}
        <RightArrow />
        {`and tap `}
        <strong>More</strong>.
      </span>
    ),
    imageUrl:
      '//images.babylist.com/image/upload/v1499990711/share-extension/shared-step-1.png',
  },
  {
    beforeText: (
      <span>
        {`Tap to turn on the `}
        <strong>Add to Babylist Button</strong>.
      </span>
    ),
    imageUrl:
      '//images.babylist.com/image/upload/v1499992849/share-extension/shared-step-2.png',
    afterText: (
      <span>
        {`If you don't see the `}
        <strong>Add to Babylist</strong>
        {` option, make sure you have the latest version of the app installed.`}
      </span>
    ),
  },
  {
    beforeText: (
      <span>
        {`Now, whenever you see something cool, tap the `}
        <Image
          alt="Share Icon"
          width={18}
          src={IOS_SHARE_ICON_IMG}
          className="mhs"
          style={{ verticalAlign: 'bottom' }}
        />
        {`, swipe `}
        <RightArrow />
        {` and tap your new `}
        <strong>Add to Babylist Button</strong>
        to add anything to your registry.
      </span>
    ),
    imageUrl:
      '//images.babylist.com/image/upload/v1499992849/share-extension/shared-step-3.png',
  },
]

const SAFARI_STEPS = [
  {
    // Step 1
    beforeText: (
      <span>
        {`Tap the `}
        <strong>share button</strong>, below.
      </span>
    ),
    imageUrl:
      '//images.babylist.com/image/upload/v1499990711/share-extension/safari-step-1.png',
  },
  ...SHARED_STEPS,
]

const CHROME_STEPS = [
  {
    // Step 1
    beforeText: (
      <span>
        {`Tap `}
        <strong>More</strong>, above.
      </span>
    ),
    imageUrl:
      '//images.babylist.com/image/upload/v1499992295/share-extension/chrome-step-1.png',
  },
  {
    // Step 2
    beforeText: (
      <span>
        {`Tap the `}
        <strong>Share icon</strong>, above.
      </span>
    ),
    imageUrl:
      '//images.babylist.com/image/upload/v1499992295/share-extension/chrome-step-2.png',
  },
  ...SHARED_STEPS,
]

interface InstructionsProps {
  browser: 'chrome' | 'safari'
}

const Instructions = ({ browser }: InstructionsProps) => {
  const steps = browser === 'chrome' ? CHROME_STEPS : SAFARI_STEPS
  const navigate = useNavigate()

  return (
    <div className="text-center mtxl">
      <div className="h3 text-bold">Enable Add to Babylist Button</div>
      <p className="small mbxl">
        {`Just ${steps.length} easy steps to start building your registry.`}
      </p>
      {map(steps, (step, idx) => (
        <Step
          key={idx}
          number={idx + 1}
          isLastStep={idx == steps.length - 1}
          {...step}
        />
      ))}
      <InfoButton
        className="mtxl"
        onClick={() => navigate(blUrls.shareExtensionHowItWorksPath)}
      >
        See How It Works
      </InfoButton>
    </div>
  )
}

interface StepProps extends Step {
  number: number
  isLastStep: boolean
}

const Step = ({
  number,
  isLastStep,
  beforeText,
  afterText,
  imageUrl,
}: StepProps) => (
  <div>
    <div className="text-bold mbs">{`Step ${number}`}</div>
    {beforeText ? <p className="small">{beforeText}</p> : null}
    {imageUrl ? (
      <Image
        role="presentation"
        className="img-responsive center-block mbm phm"
        src={imageUrl}
      />
    ) : null}
    {afterText ? <p className="small">{afterText}</p> : null}
    {!isLastStep ? (
      <hr className="center-block mvl" style={{ maxWidth: 100 }} />
    ) : null}
  </div>
)

export default Instructions
