import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isEmpty, values } from 'lodash'
import {
  getBabylistReservations,
  getStores,
  getTotalQuantity,
  getUnpurchasableReservations,
  uncompletedActiveReservations,
} from 'cart/lib'
import { SectionHeading } from 'components/headings'
import { useAutoRefresh } from 'lib/hooks'
import {
  AmazonCartItemShape,
  CartShape,
  RegistryShape,
  ReservationShape,
} from 'cart/lib/prop-types'
import BLCart from '../bl-cart'
import EmptyCart from '../empty-cart'
import AmazonCart from '../amazon-cart'
import ReservationsList from '../reservations-list'
import CartAlerts from '../cart-alerts'
import EmptyCartWave from '../empty-cart-wave'
import css from './cart-page.scss'

export const CartPage = ({
  amazonCartItems,
  babylistReservations,
  cart,
  disableBabylistOffer,
  hasAmazonCartItems,
  hasCartItems,
  hasReservations,
  maintenanceMode,
  orphanedReservations,
  processingReservations,
  registry,
  reservations,
  showPurchaseConfirmationModal,
  showPurchaseCancelModal,
  storeCount,
  totalQuantity,
  unpurchasableReservations,
  user,
  isMobile,
}) => {
  useAutoRefresh(600000) // refresh the page every 10 minutes to update the cart
  const emptyCart = !hasCartItems && !hasAmazonCartItems && !hasReservations

  return (
    <>
      <div className="container-lg ptxl-md ptl">
        <div className="mbl pbm bbs flex-row space-between">
          <SectionHeading className="mtn mbs">Your Cart</SectionHeading>
        </div>
        <CartAlerts
          items={cart.items}
          orphanedReservations={orphanedReservations}
          unpurchasableReservations={unpurchasableReservations}
          registry={registry}
        />

        <div className={css.cartItemContainer}>
          {emptyCart && <EmptyCart className={css.emptyCartContainer} />}

          {hasCartItems && (
            <BLCart
              cart={cart}
              className="mtl"
              maintenanceMode={maintenanceMode}
              registry={registry}
              reservations={babylistReservations}
              unpurchasableReservations={unpurchasableReservations}
              user={user}
              isMobile={isMobile}
            />
          )}

          {hasAmazonCartItems && <AmazonCart registry={registry} />}

          {!isEmpty(reservations) && (
            <ReservationsList
              className="mtl"
              disableBabylistOffer={disableBabylistOffer}
              maintenanceMode={maintenanceMode}
              processingReservations={processingReservations}
              registry={registry}
              reservations={reservations}
              showPurchaseConfirmationModal={showPurchaseConfirmationModal}
              showPurchaseCancelModal={showPurchaseCancelModal}
            />
          )}
        </div>
      </div>
      <div className={css.emptyCartWave}>{emptyCart && <EmptyCartWave />}</div>
    </>
  )
}

CartPage.propTypes = {
  amazonCartItems: PropTypes.arrayOf(PropTypes.shape(AmazonCartItemShape)),
  babylistReservations: PropTypes.arrayOf(PropTypes.shape(ReservationShape)),
  disableBabylistOffer: PropTypes.bool,
  cart: PropTypes.shape(CartShape),
  hasAmazonCartItems: PropTypes.bool,
  hasCartItems: PropTypes.bool,
  hasReservations: PropTypes.bool,
  maintenanceMode: PropTypes.bool,
  orphanedReservations: PropTypes.arrayOf(PropTypes.shape(ReservationShape)),
  processingReservations: PropTypes.arrayOf(PropTypes.shape(ReservationShape)),
  registry: PropTypes.shape(RegistryShape),
  reservations: PropTypes.arrayOf(PropTypes.shape(ReservationShape)),
  showPurchaseConfirmationModal: PropTypes.bool,
  showPurchaseCancelModal: PropTypes.bool,
  storeCount: PropTypes.number,
  totalQuantity: PropTypes.number,
  unpurchasableReservations: PropTypes.arrayOf(
    PropTypes.shape(ReservationShape)
  ),
  user: PropTypes.object,
}

CartPage.defaultProps = {
  amazonCartItems: [],
  babylistReservations: [],
  disableBabylistOffer: false,
  cart: {},
  hasAmazonCartItems: false,
  hasCartItems: false,
  hasReservations: false,
  maintenanceMode: false,
  orphanedReservations: [],
  processingReservations: [],
  registry: undefined,
  reservations: [],
  showPurchaseConfirmationModal: false,
  showPurchaseCancelModal: false,
  storeCount: 0,
  totalQuantity: 0,
  unpurchasableReservations: [],
  user: undefined,
}

const mapStateToProps = (state) => {
  const { cart } = state
  const amazonCartItems = values(cart.amazonCart?.items)
  const reservations = values(state.reservations)

  const babylistReservations = getBabylistReservations(reservations)
  const unpurchasedReservations = uncompletedActiveReservations(reservations)
  const unpurchasableReservations = getUnpurchasableReservations(reservations)
  const hasAmazonCartItems = !isEmpty(amazonCartItems)

  const hasCartItems =
    !!get(cart, 'items.length') ||
    !isEmpty(babylistReservations) ||
    !isEmpty(unpurchasableReservations)

  const hasReservations = !isEmpty(reservations)
  return {
    ...state,
    amazonCartItems,
    babylistReservations,
    hasAmazonCartItems,
    hasCartItems,
    hasReservations,
    reservations,
    unpurchasedReservations,
    unpurchasableReservations,
    totalQuantity: getTotalQuantity(unpurchasedReservations, cart),
    storeCount: getStores(reservations).length,
  }
}

export default connect(mapStateToProps)(CartPage)
