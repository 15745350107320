import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import Image from 'components/image'

import css from './pregnancy-email-layout.scss'
import { SectionHeading } from '../../components/headings'

const PregnancyEmailLayout = ({
  children,
  heroImage,
  heroText,
  heroTextClassName,
}) => {
  return (
    <div className="container-fluid">
      <Row className={css.flexContainer}>
        <Col
          sm={6}
          md={7}
          className={css.heroImage}
          style={{ backgroundImage: `url('${heroImage}')` }}
        />
        <Col sm={6} md={5} className={css.content}>
          <>
            <Image
              role="presentation"
              className={css.logo}
              src="//images.babylist.com/image/upload/f_auto,q_auto/v1547580354/Babylist_logo_horizontal_oawglq.svg"
              lazyLoad={false}
            />
            {heroText ? (
              <SectionHeading
                className={classNames(css.heroText, heroTextClassName)}
              >
                {heroText}
              </SectionHeading>
            ) : null}
          </>
          {children}
        </Col>
      </Row>
    </div>
  )
}

export default PregnancyEmailLayout
