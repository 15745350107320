import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Helmet } from 'react-helmet'
import PrintableGiftCard from './components/PrintableGiftCard'
import { Button } from 'baby-design'
import css from './styles.scss'

interface PrintGiftCardPageProps {
  dollarAmount: number | string
  giftCardCode: string
  giftGiverName?: string
  giftGiverMessage?: string
}

const PrintGiftCardPage = (printGiftCardProps: PrintGiftCardPageProps) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  // The way react interacts with sass appears to not populate the HTML with class definitions
  // This causes the print screen page to not reflect styles applied by the scss file
  // The HTML returned by this component contains a <style> tag to enforce styles on print
  return (
    <div className={css.printableGiftCard}>
      <Helmet
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />
      <style>
        {`
          @media print {
            .${css.printNowButton} {
              display: none;
            }

            .page-break {
              page-break-before: always;
            }
          }
        `}
      </style>

      <div className={css.printSheetWrapper}>
        <div className="page-break" />
        <PrintableGiftCard {...printGiftCardProps} ref={componentRef} />

        <Button
          variant="primary"
          size="lg"
          onClick={handlePrint}
          className={css.printNowButton}
        >
          <p className={css.printNowButtonText}>Print now</p>
        </Button>
      </div>
    </div>
  )
}

export default PrintGiftCardPage
