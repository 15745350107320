/* eslint-disable import/no-default-export */
/* eslint-disable complexity */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { ReservationShape } from 'cart/lib/prop-types'
import { connect } from 'react-redux'
import { remove } from 'lodash'
import { LinkButton } from 'components/buttons'
import RegItemNote from 'shared/reg-item-note'
import BundleComponentsList from 'shared/product-list/bundle-components-list'
import { tracking, track } from 'lib/analytics'
import RegItemImage from 'registry/components/reg-item-image'
import { getGiftCardImageUrl, getGiftCardProductId } from 'cart/lib'
import {
  changeStore,
  markAsPurchased,
  showPurchaseInfoForm,
  updateGiftAmount,
  updateQuantity,
  updateReservation,
} from '../actions'
import QuantitySelect from './quantity-select'
import GiftAmountSelect from './gift-amount-select'
import css from './gift-amount-select/gift-amount-select.scss'
import { PriceDisplay } from '../../registry/shop-registry/components/price-display'

@tracking(({ reservation }) => ({ reservation }))
export class StoreOrderItem extends Component {
  // reservation is set on comp
  @tracking((props, state, [reservationId, event]) => {
    const { storeName: reservationStoreOriginal } = props
    const reservationStoreUpdated = event.target.value
    return {
      event: track.reservationUpdated,
      eventType: track.EventType.CHANGED_STORE,
      reservationStoreOriginal,
      reservationStoreUpdated,
    }
  })
  handleChangeStore(reservationId, e) {
    const { onChangeStore, storeName } = this.props
    onChangeStore(reservationId, storeName, e.target.value)
  }

  handleUpdateGiftAmount(reservationToken, e) {
    const {
      reservation: { giftCardOptions },
      onUpdateGiftAmount,
    } = this.props
    const giftAmount = e.target.value
    const productId = getGiftCardProductId(giftAmount, giftCardOptions)
    onUpdateGiftAmount(reservationToken, { giftAmount, productId })
  }

  render() {
    const {
      className,
      currentOffer,
      disableBabylistOffer,
      onSetReservationToRemove,
      onUpdateQuantity,
      reservation,
      useRegistryDiscount,
    } = this.props

    const {
      description,
      imgUrl,
      offers,
      quantityNeeded,
      title,
      purchaseQuantityLimit,
      showPurchaseQuantityLimit,
      price,
    } = reservation.regItem

    const {
      bundleComponents,
      displayTitle,
      id,
      giftAmount,
      isEligibleForRegistryDiscount,
      isGroupGiftContribution,
      giftCardOptions,
      productId,
      store,
      quantity,
      token,
    } = reservation

    const isGiftCard = giftCardOptions?.length > 0
    const giftCardImgUrl = isGiftCard
      ? getGiftCardImageUrl(productId, giftCardOptions)
      : null

    const shouldDisplayQuantitySelect =
      reservation.regItem.quantity &&
      quantity + quantityNeeded > 1 &&
      !isGroupGiftContribution &&
      !isGiftCard

    if (disableBabylistOffer) {
      remove(offers, (o) => o.storeName === 'Babylist')
    }

    const bundleComponentList =
      store === 'Babylist' &&
      !isGroupGiftContribution &&
      bundleComponents?.length > 0 ? (
        <BundleComponentsList
          products={bundleComponents}
          className="pbm"
          showShippingMsg
        />
      ) : null
    const priceDisplayComponent = (
      <PriceDisplay
        price={giftAmount || currentOffer?.price || price}
        isAvailableOnBabylist
        isEligibleForRegistryDiscount={
          useRegistryDiscount && isEligibleForRegistryDiscount
        }
        registryDiscountAvailable={
          useRegistryDiscount && isEligibleForRegistryDiscount
        }
      />
    )

    const removeComponent = (
      <span className="h7 mbm mbn-sm">
        <LinkButton className="xsmall pan" onClick={onSetReservationToRemove}>
          Remove
        </LinkButton>
      </span>
    )
    return (
      <div className={className}>
        <div id={id} className="flex ptl pbl-sm">
          <div className="prl">
            <RegItemImage src={giftCardImgUrl || imgUrl} width={100} />
          </div>
          <div className="flex-grow prs-md">
            <div className="mbm">
              <div className="h5 mtn">
                {isGroupGiftContribution || isGiftCard ? displayTitle : title}
                {isGroupGiftContribution && (
                  <div className="h6 mbm">{title}</div>
                )}
              </div>
            </div>

            {description ? (
              <RegItemNote description={description} className="h6 mtn mbm" />
            ) : null}

            <div>
              {isGiftCard ? (
                <GiftAmountSelect
                  defaultGiftAmount={giftAmount}
                  options={giftCardOptions.map((gc) => gc.price)}
                  onChange={(e) => this.handleUpdateGiftAmount(token, e)}
                  wrapperClassName={css.inlineGiftAmountGroup}
                  size="small"
                />
              ) : null}
              {shouldDisplayQuantitySelect ? (
                <QuantitySelect
                  quantity={quantity}
                  quantityNeeded={quantityNeeded}
                  onUpdateQuantity={(e) =>
                    onUpdateQuantity(token, e.target.value)
                  }
                  purchaseQuantityLimit={purchaseQuantityLimit}
                  showPurchaseQuantityLimit={showPurchaseQuantityLimit}
                />
              ) : null}
              <div className="hidden-xs">
                {bundleComponentList}
                {removeComponent}
              </div>
            </div>
          </div>
          <div className="hidden-xs">{priceDisplayComponent}</div>
        </div>

        <div className="visible-xs">
          {priceDisplayComponent}
          {bundleComponentList}
          <div className="mvl text-right">{removeComponent}</div>
        </div>
      </div>
    )
  }
}

StoreOrderItem.propTypes = {
  className: PropTypes.string,
  disableBabylistOffer: PropTypes.bool,
  onChangeStore: PropTypes.func.isRequired,
  onSetReservationToRemove: PropTypes.func.isRequired,
  onUpdateGiftAmount: PropTypes.func.isRequired,
  onUpdateQuantity: PropTypes.func.isRequired,
  reservation: PropTypes.shape(ReservationShape).isRequired,
  storeName: PropTypes.string.isRequired,
  useRegistryDiscount: PropTypes.bool.isRequired,
}

StoreOrderItem.defaultProps = {
  className: '',
  disableBabylistOffer: false,
}

export default connect(undefined, {
  onChangeStore: changeStore,
  onMarkAsPurchased: markAsPurchased,
  onShowPurchaseInfoForm: showPurchaseInfoForm,
  onUpdateGiftAmount: updateGiftAmount,
  onUpdateReservation: updateReservation,
  onUpdateQuantity: updateQuantity,
})(StoreOrderItem)
