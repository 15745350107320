import classNames from 'classnames'
import PropTypes from 'prop-types'

const ReservedItemsHeader = ({ registry, className }) => {
  return (
    <div className={classNames(className, 'text-bold text-category-underline')}>
      From {registry.title}
    </div>
  )
}

ReservedItemsHeader.propTypes = {
  className: PropTypes.string,
  registry: PropTypes.object.isRequired,
}

ReservedItemsHeader.defaultProps = {
  className: undefined,
}

export default ReservedItemsHeader
