/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { newRegistryPath, registryHomePath } from 'lib/urls'
import { track, withContextualizedTracking } from 'lib/analytics'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { LoadingHeart } from 'baby-design'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import {
  SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT,
  SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT,
} from 'shared/constants'
import EmailForm from '../EmailForm/EmailForm'
import PasswordForm from '../PasswordForm/PasswordForm'
import css from './LoginForm.scss'
import { LoginFormProps, LoginFormState } from './LoginForm.types'
import { postLoginData, BabylistLoginErrorResponseV3 } from './api'
import InfoModal from '../InfoModal'

const LoginForm = ({
  email,
  nonce,
  passwordFormActive,
  popup,
  ssoEmailVerificationProvider,
  requestedUrl,
  ssoEmailVerificationRequired,
  ssoEnabled,
}: LoginFormProps) => {
  const [currentUser, setCurrentUser] = useCurrentUser()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [state, setState] = useState({
    email: email || '',
    errors: {},
    googleCredential: '',
    loading: false,
    password: '',
    passwordFormActive: passwordFormActive || false,
    ssoEmailVerificationRequired: ssoEmailVerificationRequired || false,
    ssoEmailVerificationProvider: ssoEmailVerificationProvider || 'Email',
  } as LoginFormState)

  const redirectUrl = (hasRegistry = false) => {
    const defaultUrl = hasRegistry ? registryHomePath : newRegistryPath

    return requestedUrl ? String(requestedUrl) : defaultUrl
  }

  const updateState = (newState: LoginFormState) =>
    setState((prevState: LoginFormState) => ({ ...prevState, ...newState }))

  const togglePasswordForm = () => {
    if (!state.passwordFormActive === false) {
      updateState({
        email: '',
        googleCredential: '',
        ssoEmailVerificationRequired: false,
      })
    }

    updateState({ passwordFormActive: !state.passwordFormActive })
  }

  const handleVerifyRecaptcha = async () => {
    if (!executeRecaptcha) {
      window.console.log('Recaptcha has not been loaded')

      return undefined
    }

    const result = await executeRecaptcha('login')
    return result
  }

  const loginRequest = async (event: React.FormEvent) => {
    event.preventDefault()

    if (state.loading) {
      return
    }

    updateState({ loading: true })

    const recaptchaToken = await handleVerifyRecaptcha()

    try {
      const resp = await postLoginData(
        state.googleCredential,
        state.email,
        state.password,
        recaptchaToken
      )

      const response = await resp.json()

      handleBabylistResponse(
        state.ssoEmailVerificationProvider || 'Email',
        response,
        setPasswordError
      )
    } catch (error) {
      const errorResponse = error as BabylistLoginErrorResponseV3

      updateState({
        errors: { password: errorResponse.error?.message },
        loading: false,
      })
    }
  }

  const setPasswordError = (message: string) => {
    updateState({
      errors: {
        password: message,
      },
    })
  }

  // eslint-disable-next-line complexity
  const handleBabylistResponse = (
    provider: 'Apple' | 'Email' | 'Google',
    response: any,
    setError: (error: string) => void
  ) => {
    updateState({ loading: true })

    if (response.oktaRedirect) {
      window.location.href = response.oktaRedirect
    } else if (response.user) {
      track.logInSubmitted({
        eventLocation: getEventLocation(),
        userAuthenticationMethod: provider,
        eventType: track.EventType.SUCCESSFUL,
      })

      window.currentUser = response.user
      setCurrentUser(response.user)

      track.logInCompleted({
        eventLocation: getEventLocation(),
        userAuthenticationMethod: provider,
        userId_: currentUser?.id.toString() || '',
        userEmail: currentUser?.email || '',
        primaryRegistryId: currentUser?.registryId || 0,
        userCreatedAt: currentUser?.createdAt || '',
        arrivalDate: currentUser?.family?.arrivalDate || '',
        isPartner: currentUser?.currentRegistry?.ownerId !== currentUser?.id,
      })

      const hasRegistry = !!response.user.registryUrlSlug

      if (popup) {
        window.opener?.postMessage({
          type: 'currentUser',
          data: response.user,
        })

        window.close()
      } else {
        window.location.href = redirectUrl(hasRegistry)
      }
    } else if (response.isExistingUser === false) {
      PubSub.publish(SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT, {
        renderContent: (cm: () => void) => (
          <InfoModal
            closeModal={cm}
            email={response.missingData.user.email}
            provider={provider}
            type="create"
          />
        ),
      })

      updateState({ loading: false })

      PubSub.publish(SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT)
    } else if (response.missingData?.user?.isEmailVerified === false) {
      updateState({
        email: response.missingData.user.email,
        loading: false,
        passwordFormActive: true,
        ssoEmailVerificationRequired: true,
        ssoEmailVerificationProvider: provider,
      })
    } else {
      track.logInSubmitted({
        eventLocation: getEventLocation(),
        userAuthenticationMethod: provider,
        eventType: track.EventType.ERROR_MINUS_INVALID_CREDENTIALS,
      })

      setError(response.error?.message)

      updateState({
        loading: false,
      })
    }
  }

  const getEventLocation = () => {
    if (!state.passwordFormActive) {
      return track.EventLocation.LOG_IN_MINUS_LOG_IN_SCREEN
    }

    if (state.ssoEmailVerificationRequired) {
      return track.EventLocation.LOG_IN_MINUS_VERIFY_EMAIL_PASSWORD
    }

    return track.EventLocation.LOG_IN_MINUS_SUBMIT_EMAIL_PASSWORD
  }

  useEffect(() => {
    if (!state.passwordFormActive) return

    setTimeout(() => {
      const focusRef = document.querySelector(
        'input[name="password"]'
      ) as HTMLInputElement

      focusRef?.focus()
    }, 300)
  }, [state.passwordFormActive])

  useEffect(() => {
    track.logInStarted({
      eventLocation: track.EventLocation.LOG_IN_MINUS_LOG_IN_SCREEN,
    })
  }, [])

  return (
    <>
      {state.loading && (
        <div className={css.loginFormLoaderBackground}>
          <LoadingHeart className={css.loadingIcon} />
        </div>
      )}
      <form onSubmit={loginRequest}>
        <div className={css.loginFormContainer}>
          <div
            className={`${css.loginFormSlideContainer} ${
              state.passwordFormActive ? css.slideOut : css.slideIn
            }`}
          >
            <EmailForm
              handleBabylistResponse={handleBabylistResponse}
              nonce={nonce}
              ssoEnabled={ssoEnabled}
              state={state}
              togglePasswordForm={togglePasswordForm}
              updateState={updateState}
            />
            <PasswordForm
              state={state}
              togglePasswordForm={togglePasswordForm}
              updateState={updateState}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default withContextualizedTracking()(LoginForm)
