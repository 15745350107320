import PropTypes from 'prop-types'
import classNames from 'classnames'
import { numberToCurrency } from 'lib/money'
import { getRegistryDiscountPrice } from 'cart/lib'
import { registryDiscountPath } from 'lib/urls'
import Link from 'components/link'
import css from './price-display.scss'

export const PriceDisplay = ({
  msrp,
  price,
  isAvailableOnBabylist,
  isEligibleForRegistryDiscount,
  registryDiscountAvailable,
}) => {
  const displayRegistryDiscountPrice =
    isEligibleForRegistryDiscount && isAvailableOnBabylist
  const displayDiscountIneligibleText =
    registryDiscountAvailable &&
    !isEligibleForRegistryDiscount &&
    isAvailableOnBabylist
  const displayMsrp = isAvailableOnBabylist && !!msrp && msrp !== price

  const registryDiscountPrice =
    isEligibleForRegistryDiscount && getRegistryDiscountPrice(price)

  return (
    <div className="flex col align-end small">
      {displayMsrp && (
        <del className="text-muted pas">{numberToCurrency(msrp)}</del>
      )}
      <div className="pas">{numberToCurrency(price)}</div>
      {displayRegistryDiscountPrice && (
        <div className="text-bold mts mbl">
          With registry discount:{' '}
          <span className={classNames(css.registryDiscountPrice, 'no-wrap')}>
            {numberToCurrency(registryDiscountPrice)}
          </span>
        </div>
      )}
      {displayDiscountIneligibleText && (
        <div className="text-italic">
          <Link url={registryDiscountPath} newWindow>
            Ineligible
          </Link>{' '}
          for discount
        </div>
      )}
    </div>
  )
}

PriceDisplay.propTypes = {
  isAvailableOnBabylist: PropTypes.bool.isRequired,
  msrp: PropTypes.number,
  price: PropTypes.number.isRequired,
  isEligibleForRegistryDiscount: PropTypes.bool.isRequired,
  registryDiscountAvailable: PropTypes.bool.isRequired,
}

PriceDisplay.defaultProps = {
  msrp: undefined,
}
