import { Component } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, isObject, omit, range, set } from 'lodash'
import { Collapse } from 'react-bootstrap'

import * as BLConstants from 'shared/constants'
import PregnancyEmailLayout from './pregnancy-email-layout'
import { InfoButton } from 'components/buttons'
import DueDateSelect from 'components/due-date-select'
import { Checkbox, Input } from 'components/forms'
import Link from 'components/link'
import Image from 'components/image'
import { createValidator, dateString, required } from 'lib/form-validation'
import { parseErrors } from 'lib/api-utils'
import { today } from 'lib/date'

import css from './pregnancy-email-birthday.scss'
import * as api from './api'

const currentYear = today().getFullYear()
const years = range(currentYear - 20, currentYear + 1).reverse()

const DEFAULT_BG_IMAGE =
  'https://images.babylist.com/image/upload/c_crop,g_west,h_1600,w_1456/v1501537722/born-this-week-baby.png'
const TWINS_BG_IMAGE =
  'https://images.babylist.com/image/upload/c_crop,g_west,h_1600,w_1456/v1501538602/born-this-week-twins.jpg'
const CONFIRMATION_BG_IMAGE =
  'https://images.babylist.com/image/upload/q_auto:eco/v1501538431/born-this-week-yay.jpg'

export const getValidator = function (twins) {
  let rules = {
    birthDate: [required, dateString],
    'names[0].firstName': [required],
  }

  if (twins) {
    rules['names[1].firstName'] = [required]
  }
  return createValidator(rules)
}

export class PregnancyEmailBirthday extends Component {
  static propTypes = {
    familyId: PropTypes.number.isRequired,
    restrictedAccessToken: PropTypes.string.isRequired,
  }

  state = {
    submitting: false,
    submitted: false,
    fields: {
      names: [
        { firstName: '', middleName: '' },
        { firstName: '', middleName: '' },
      ],
      shareInBornThisWeek: false,
    },
    errors: {},
    twins: false,
  }

  render() {
    return (
      <PregnancyEmailLayout
        heroImage={this.getHeroImage()}
        heroTextClassName={
          this.state.submitted ? css.heroTextConfirmation : null
        }
        heroText={this.renderHeroText()}
      >
        <div className={css.contentBody}>
          {this.state.submitted ? this.renderConfirmation() : this.renderForm()}
        </div>

        {/* Preload all of our css background images so we can transition smoothly */}
        <Image
          role="presentation"
          className="hidden"
          src={TWINS_BG_IMAGE}
          lazyLoad={false}
        />
        <Image
          role="presentation"
          className="hidden"
          src={CONFIRMATION_BG_IMAGE}
          lazyLoad={false}
        />
      </PregnancyEmailLayout>
    )
  }

  renderConfirmation() {
    return this.state.fields.shareInBornThisWeek ? (
      <div>
        <div className="mtm">
          Look out for a Babylist Babes shoutout from us in the Hello Baby email
          :)
        </div>
      </div>
    ) : null
  }

  renderForm() {
    let { errors, fields } = this.state

    return (
      <form
        className={css.birthdayForm}
        onSubmit={this.handleSubmit.bind(this)}
      >
        <DueDateSelect
          className={css.dateSelect}
          error={errors.birthDate}
          label={`${this.state.twins ? "Twin's" : "Baby's"} Birth Date`}
          onChange={this.handleChange.bind(this, 'birthDate')}
          preferFutureDates={false}
          years={years}
        />

        {this.renderNameFieldset(0)}
        <Collapse in={this.state.twins}>{this.renderNameFieldset(1)}</Collapse>

        <div className="h6 mtn">
          <Link onClick={() => this.setState({ twins: !this.state.twins })}>
            {this.state.twins ? 'Reset' : 'I had twins'}
          </Link>
        </div>

        <Checkbox
          checked={fields.shareInBornThisWeek}
          label={
            'Can we share your baby\'s name and birthdate in our "Born this Week" email feature?'
          }
          onChange={() =>
            this.handleChange(
              'shareInBornThisWeek',
              !fields.shareInBornThisWeek
            )
          }
        />

        <InfoButton
          type="submit"
          className={css.submitBtn}
          submitting={this.state.submitting}
        >
          Submit
        </InfoButton>
      </form>
    )
  }

  renderHeroText() {
    if (this.state.submitted && this.state.twins) {
      return `Hooray on baby ${this.state.fields.names[0].firstName} and ${this.state.fields.names[1].firstName}'s arrival!`
    } else if (this.state.submitted) {
      return `Hooray on baby ${this.state.fields.names[0].firstName}'s arrival!`
    } else {
      return 'Hello, World!'
    }
  }

  renderNameFieldset(idx) {
    let errors = this.state.errors
    let labelPrefix = this.state.twins ? `Baby ${idx + 1}` : "Baby's"
    let name = this.state.fields.names[idx]

    return (
      <fieldset key={idx}>
        <Input
          className={css.nameInput}
          error={get(this.state.errors, `names[${idx}].firstName`)}
          label={`${labelPrefix} First Name`}
          onChange={this.handleChange.bind(this, `names[${idx}].firstName`)}
          type="text"
          value={name.firstName}
        />
        <Input
          className={css.nameInput}
          error={get(this.state.errors, `names[${idx}].middleName`)}
          label={`${labelPrefix} Middle Name (Optional)`}
          onChange={this.handleChange.bind(this, `names[${idx}].middleName`)}
          type="text"
          value={name.middleName}
        />
      </fieldset>
    )
  }

  getHeroImage() {
    if (this.state.submitted) {
      return CONFIRMATION_BG_IMAGE
    } else if (this.state.twins) {
      return TWINS_BG_IMAGE
    } else {
      return DEFAULT_BG_IMAGE
    }
  }

  handleChange(field, eventOrValue) {
    let value = isObject(eventOrValue)
      ? eventOrValue.target.value
      : eventOrValue
    this.setFormValue(field, value)
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.validateForm()) {
      this.setState({ submitting: true })
      let formData = {
        family: {
          id: this.props.familyId,
          arrival_date: this.state.fields.birthDate,
          share_babes: this.state.fields.shareInBornThisWeek,
          births: {
            names: this.state.twins
              ? this.state.fields.names
              : [this.state.fields.names[0]],
            bornThisWeekOptIn: this.state.fields.shareInBornThisWeek,
          },
        },
      }

      api
        .saveBirthday(formData, this.props.restrictedAccessToken)
        .then((resp) => {
          this.setState({ submitting: false, submitted: true })
        })
        .catch((resp) => {
          this.setState({ submitting: false })
          PubSub.publish(BLConstants.FLASH_MESSAGE, {
            message: parseErrors(resp)._error,
            variant: 'danger',
          })
        })
    }
  }

  setFormValue(name, value) {
    let fields = { ...this.state.fields }
    set(fields, name, value)
    this.setState({
      fields,
      errors: omit(this.state.errors, name),
    })
  }

  validateForm() {
    let errors = getValidator(this.state.twins)(this.state.fields)
    this.setState({ errors })
    return isEmpty(errors)
  }
}

export default PregnancyEmailBirthday
