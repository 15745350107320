import { filter, find, findIndex, get, includes, sortBy } from 'lodash'
import { parseLocalISODate, today } from 'lib/date'
import {
  MERGE_REGISTRY,
  SET_SHIPPING_ADDRESS,
  SET_ARRIVAL_DATE,
  SET_IS_ARRIVAL_DATE_KNOWN,
  SET_PLANNING_STAGE,
  SET_REGISTRY,
  SET_CASH_FUND_PREFERENCES,
  SET_GIFT_GIVER_ADDRESS,
  UPDATE_SETUP_PROGRESS_GOALS,
} from '../actions'
import { GOAL_STATES, REGISTRY_ROLES, HIDDEN_GOALS } from '../constants'

const initialState = { uiState: {} }

const registryReducer = (state = initialState, action) => {
  switch (action.type) {
    case MERGE_REGISTRY:
      return { ...state, ...action.registry }
    case SET_REGISTRY:
      return action.registry
    case SET_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.shippingAddress }
    case SET_GIFT_GIVER_ADDRESS:
      return { ...state, giftGiverAddress: action.giftGiverAddress }
    case SET_ARRIVAL_DATE:
      return {
        ...state,
        family: { ...state.family, arrivalDate: action.arrivalDate },
      }
    case SET_IS_ARRIVAL_DATE_KNOWN:
      return {
        ...state,
        family: {
          ...state.family,
          isArrivalDateKnown: action.isArrivalDateKnown,
        },
      }
    case SET_PLANNING_STAGE:
      return {
        ...state,
        family: { ...state.family, planningStage: action.planningStage },
      }
    case SET_CASH_FUND_PREFERENCES:
      return { ...state, cashFundPreferences: { ...action.preferences } }
    case UPDATE_SETUP_PROGRESS_GOALS:
      return {
        ...state,
        setupProgress: {
          ...state.setupProgress,
          goals: action.goals,
        },
      }

    default:
      return state
  }
}

export default registryReducer

export const getRegistryIsBabyRegistry = (state) =>
  state?.type === null || state?.type === 'baby'
export const getRegistryIsBirthday = (state) =>
  get(state, 'type') === 'birthday'
export const getArrivalDate = (state) => get(state, 'family.arrivalDate')
export const getArrivalKnown = (state) => state.family.isArrivalDateKnown
export const getCashFundsCount = (state) => state.cashFundsCount
export const getCashFundPreferences = (state) => state.cashFundPreferences
export const getCollaborators = (state) => state.collaborators
export const getCreatedAt = (state) => state.createdAt
export const getCurrentShower = (state) => {
  const { showers } = getShowerInfo(state)

  const futureShowers = showers.filter((shower) => {
    if (shower.date) {
      return new Date(parseLocalISODate(shower.date)) >= today()
    }
    return false
  })

  return futureShowers.length ? futureShowers[0] : {}
}
export const getEligibleForHelloBabyBox = (state) =>
  state.isEligibleForHelloBabyBox
export const getEligibleForRegistryDiscount = (state) =>
  state.isEligibleForRegistryDiscount
export const getExpectingChildren = (state) => state.family.expectingChildren
export const getExternalRegistriesCount = (state) =>
  state.externalRegistriesCount
export const getFirstNames = (state) => state.firstNames
export const getGiftsPurchasedCount = (state) => state.giftsPurchasedCount
export const getHasPartner = (state) => state.family.hasPartner
export const getHasVisitorPassword = (state) => !!state.visitorPassword
export const getHelloBabyBoxQualificationGoals = (state) =>
  filter(
    getRegistrySetupProgress(state).goals,
    'isRequiredForHelloBabyBoxQualification'
  )
export const getId = (state) => state.id
export const getIndexOfCurrentShower = (state) =>
  findIndex(
    getShowers(state),
    (shower) => shower.id == getCurrentShower(state).id
  )
export const getIsDotcomHbbAvailable = (state) => state.isDotcomHbbAvailable
export const getOwnerId = (state) => state.ownerId
export const getPartner = (state) => get(state, 'family.partner')
export const getPartnerEmail = (state) => get(getPartner(state), 'email')
export const getPartnerFirstName = (state) =>
  get(getPartner(state), 'firstName')
export const getPartnerLastName = (state) => get(getPartner(state), 'lastName')
export const getPartnerCollaborator = (state) =>
  find(getCollaborators(state), (c) => c.role == REGISTRY_ROLES.PARTNER)
export const getPlanningStage = (state) => state.family.planningStage
export const getReceivingHelloBabyBox = (state) => state.isReceivingHelloBabyBox
export const getRegItemsBabylistCount = (state) => state.regItemsBabylistCount
export const getRegItemsCount = (state) => state.regItemsCount
export const getRegItemsOtherCount = (state) => state.regItemsOtherCount
export const getRegistryDescription = (state) => state.description
export const getRegistryDiscountStartsAt = (state) =>
  state.registryDiscountStartsAt
export const getRegistryDiscountEndsAt = (state) => state.registryDiscountEndsAt
export const getRegistryPhoto = (state) => state.photoUrl
export const getRegistrySetupGoal = (state, goalId) =>
  find(getRegistrySetupProgress(state).goals, ({ id }) => id == goalId)
export const getRegistrySetupGoalIsCompleted = (state, goalId) =>
  getRegistrySetupGoal(state, goalId).state === GOAL_STATES.COMPLETE
export const getRegistryShareUrl = (state) =>
  getRegistryUrl(state).match(/https?:\/\/(.*)/)[1]
export const getRegistrySetupProgress = (state) => state.setupProgress || {} // TODO-MAX: Handle with initial state and don't squash with setRegistry
export const getRegistrySetupGoalsToDisplay = (state) =>
  filter(
    getRegistrySetupProgress(state).goals,
    (goal) => !includes(HIDDEN_GOALS, goal.id)
  )
export const getRegistryTitle = (state) => state.title
export const getRegistryUIState = (state) => state.uiState
export const getRegistryUrl = (state) => state.url
export const getRegistryUrlSlug = (state) => state.urlSlug
export const getReservationCount = (state) => state.reservationCount
export const getSearchLevel = (state) => state.searchLevel
export const getShippingAddress = (state) => state.shippingAddress
export const getShippingConsolidation = (state) => state.shippingConsolidation
export const getShowerInfo = (state) => state.showerInfo || { showers: [] } // TODO-MAX: Handle with initial state and don't squash with setRegistry
export const getShowers = (state) => {
  const { showers } = state.showerInfo
  if (showers && showers.length) {
    return sortBy(showers, (shower) => new Date(shower.date).getTime())
  }
  return null
}
export const getUseShopifyHelloBabyBoxCheckout = (state) =>
  state.isDotcomHbbAvailable
export const getVisitorPassword = (state) => state.visitorPassword
export const getWantsInsertCards = (state) => state.wantsInsertCards
export const getIsShippingAddressPrivate = (state) =>
  state.isShippingAddressPrivate
