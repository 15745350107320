require('./init-global-components')

const ns = typeof window === 'undefined' ? global : window

// bl_registry/add_item/new
ns.SuggestRegItemApp = require('./registry/components/suggest-reg-item').default

// bl_registry/checklist/index
ns.ChecklistApp = require('./checklist/containers/checklist-app').default

ns.AppInstallPromptApp =
  require('./checklist/pages/AppInstallPromptApp').default

// bl_registry/email_preferences/show
ns.EmailPreferences = require('./email_preferences').default

// bl_registry/gift_giver_preferences/show
ns.GiftGiverPreferences = require('./gift_giver_preferences').default

// bl_registry/orders/show
ns.OrderApp = require('./order/components/order-app').default

// bl_registry/pages/babylist_babes
ns.BabylistBabes = require('./pages/babylist-babes').default

// bl_registry/pages/due_date_calculator
ns.DueDateCalculatorApp =
  require('./due_date_calculator/containers/due-date-calculator-app').default

// bl_registry/pages/diaper_calculator
ns.DiaperCalculatorApp = require('./diaper_calculator').default

// bl_registry/pages/home
ns.Home = require('./home').default

// bl_registry/pages/pregnany_email_birthday
ns.PregnancyEmailBirthday =
  require('./pages/pregnancy-emails/pregnancy-email-birthday').default

// bl_registry/pages/pregnany_email_signup
ns.PregnancyEmailSignup =
  require('./pages/pregnancy-emails/pregnancy-email-signup').default

// bl_registry/pages/registry_discount
ns.RegistryDiscount = require('./pages/registry-discount').default

ns.PromotionBanner = require('./components/banners/promotion-banner').default

// bl_registry/pages/share_extension
ns.ShareExtension = require('./pages/share-extension/share-extension').default

// bl_registry/reservation/show
ns.GiftGiverReservationsApp =
  require('./cart/containers/gift-giver-reservations-app').default

// bl_registry/return/show
ns.ReturnsApp = require('./returns/components/returns-app').default

// bl_registry/review_answers/new
ns.UserReviewContainer = require('./user_reviews/user-review-container').default // review_answers

ns.BabylistCribs = require('./pages/babylist-cribs').default

// bl_registry/babyloss/index
ns.BabylossApp = require('./babyloss/BabylossApp').default

ns.BabyRegistryChecklist = require('./pages/BabyRegistryChecklist').default

ns.ChecklistAcqex = require('./pages/ChecklistAcqex').default

ns.CouponsPage = require('./pages/CouponsPage').default

ns.LoginPage = require('./LoginPage').default

ns.ForgotPasswordPage = require('./ForgotPasswordPage').default

ns.PrintGiftCardPage = require('./PrintGiftCardPage').default

ns.ReactRailsUJS = require('react_ujs')
