import * as api from 'cart/api'

import { Modal, ModalBody } from 'components/modal'
import { Component } from 'react'
import { errorMessage, infoMessage } from 'lib/flash-message'
import { get, partition } from 'lodash'
import { track, tracking } from 'lib/analytics'

import PropTypes from 'prop-types'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { connect } from 'react-redux'
import { setCart } from 'cart/actions'
import { getProductRecommendations } from 'cart/reducers'
import { buildCartItems, getSubTotal } from 'cart/lib'
import { numberToCurrency } from 'lib/money'
import Card from 'components/card'
import RemoveBLCartItem from '../remove-bl-cart-item'
import OutOfStockCart from '../out-of-stock-cart'
import BLCartOrder from '../bl-cart-order'
import { RegistryShape, CartShape } from '../../lib/prop-types'
import { mapHeroesToProductCards } from 'store/components/product-grid'
import { FREE_SHIPPING_THRESHOLD } from 'shared/constants'
import ProductRecommendationSlider from 'cart/components/product-recommendations-slider'

const BLCartContent = ({
  maintenanceMode,
  registry,
  cart,
  productRecommendations,
  reservations,
  unpurchasableReservations,
  onRemoveItem,
  isMobile,
}) => {
  const [outOfStockItems, inStockItems] = partition(
    cart.items,
    (item) => item.isOutOfStock
  )

  // Calculate order amounts
  const cartItems = buildCartItems(null, cart, reservations, 'Babylist', false)
  const subTotal = getSubTotal(cartItems)
  const totalNeededForFreeShipping = FREE_SHIPPING_THRESHOLD - subTotal

  const cartWithInStockItems = { ...cart, items: inStockItems }
  const hasStoreItems = inStockItems.length > 0
  const hasReservations = reservations.length > 0
  const hasOutOfStockItems = outOfStockItems.length > 0
  const hasUnpurchasableItems = unpurchasableReservations.length > 0

  const recommendationTitle =
    subTotal >= FREE_SHIPPING_THRESHOLD
      ? `More Items We Think You'll Love.`
      : `So close! Add ${numberToCurrency(
          totalNeededForFreeShipping
        )} more to qualify for Free Shipping.`

  let content

  switch (true) {
    case hasStoreItems || hasReservations:
      content = (
        <BLCartOrder
          cart={cartWithInStockItems}
          maintenanceMode={maintenanceMode}
          onRemoveCartItem={onRemoveItem}
          registry={registry}
          reservations={reservations}
          taxRate={get(registry, 'shippingAddress.taxRate', 0)}
          useRegistryDiscount={
            cart.isEligibleForRegistryDiscount && cart.applyRegistryDiscount
          }
          footerComponent={
            <div>
              {!!productRecommendations.length && (
                <div className="clearfix">
                  <div className="col-md-8">
                    <ProductRecommendationSlider
                      title={recommendationTitle}
                      products={productRecommendations.map((p) =>
                        mapHeroesToProductCards(p)
                      )}
                      originalCartSubTotal={parseInt(subTotal.toFixed(2))}
                      isMobile={isMobile}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              )}
              {(hasOutOfStockItems || hasUnpurchasableItems) && (
                <Card.Footer background="muted">
                  <OutOfStockCart
                    items={outOfStockItems}
                    onRemoveItem={onRemoveItem}
                    unpurchasableReservations={unpurchasableReservations}
                  />
                </Card.Footer>
              )}
            </div>
          }
        />
      )
      break
    case hasOutOfStockItems || hasUnpurchasableItems:
      content = (
        <Card>
          <OutOfStockCart
            items={outOfStockItems}
            onRemoveItem={onRemoveItem}
            unpurchasableReservations={unpurchasableReservations}
          />
        </Card>
      )
      break
    default:
      content = null
      break
  }

  return content
}

@tracking()
export class Cart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cartItemToRemove: null,
    }
  }

  @tracking((props, { cartItemToRemove: cartItem }) => ({
    event: track.cartModified,
    cartItem,
    eventType: cartItem.isOutOfStock
      ? track.EventType.ITEM_REMOVED_MINUS_OUT_OF_STOCK
      : track.EventType.ITEM_REMOVED,
  }))
  handleRemoveCartItem = () => {
    const { onRemoveCartItemSuccess } = this.props
    const { cartItemToRemove } = this.state
    api
      .removeCartItem(cartItemToRemove)
      .then((resp) => {
        this.handleSetCartItemToRemove(null)
        onRemoveCartItemSuccess(resp.cart)
        PubSub.publish(UPDATE_CART_SUMMARY)
        infoMessage('Your item has been removed!')
      })
      .catch(() => {
        errorMessage()
      })
  }

  handleSetCartItemToRemove = (cartItemToRemove) => {
    this.setState({ cartItemToRemove })
  }

  render() {
    const { cartItemToRemove } = this.state

    const {
      cart,
      maintenanceMode,
      productRecommendations,
      registry,
      reservations,
      unpurchasableReservations,
      isMobile,
    } = this.props

    return (
      <>
        <div className="pbxl">
          <BLCartContent
            maintenanceMode={maintenanceMode}
            cart={cart}
            productRecommendations={productRecommendations}
            reservations={reservations}
            registry={registry}
            unpurchasableReservations={unpurchasableReservations}
            onRemoveItem={this.handleSetCartItemToRemove}
            isMobile={isMobile}
          />
        </div>
        {cartItemToRemove && (
          <Modal
            show={Boolean(cartItemToRemove)}
            title="Remove from Cart?"
            onHide={this.handleSetCartItemToRemove}
          >
            <ModalBody>
              <RemoveBLCartItem
                cartItem={cartItemToRemove}
                onHide={this.handleSetCartItemToRemove}
                onRemove={this.handleRemoveCartItem}
              />
            </ModalBody>
          </Modal>
        )}
      </>
    )
  }
}

Cart.propTypes = {
  cart: PropTypes.shape(CartShape).isRequired,
  maintenanceMode: PropTypes.bool.isRequired,
  registry: PropTypes.shape(RegistryShape).isRequired,
  reservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  unpurchasableReservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  onRemoveCartItemSuccess: PropTypes.func.isRequired,
}

Cart.defaultProps = {
  productRecommendations: [],
  user: null,
}

const mapStateToProps = (state) => {
  return {
    productRecommendations: getProductRecommendations(state),
  }
}

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, {
  onRemoveCartItemSuccess: setCart,
})(Cart)
