import MultiItemSlider, {
  MultiItemSliderProps,
} from 'components/MultiItemSlider'
import { ProductCardData } from '../../../global'
import ProductCard from '../../../store/components/product-card'
import ProductCardAction from './ProductCardAction'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import ProductCardV2 from '../../../store/components/ProductCardV2'

export interface ProductSliderProps extends MultiItemSliderProps {
  originalCartSubTotal?: number | null
  /**
   * A  list of products containing the data to render a `<ProductCard />`.
   */
  products: ProductCardData[]
  /**
   * Any shared tracking metadata. This will be passed to the viewed and clicked event.
   * @default {}
   */
  trackingData?: any
  /**
   * The title of the section. This will be displayed above the slider.
   */
  title: string
  isMobile?: boolean
}

export default function ProductRecommendationSlider({
  originalCartSubTotal,
  products,
  title,
  trackingData = {},
  isMobile,
  ...rest
}: ProductSliderProps) {
  const { flagValue: redesignVariationValue } = useFeatureFlag(
    '24c_ex_shop_pdp_redesign_rollout',
    'control'
  )
  const isInPDPRedesignFeature = redesignVariationValue !== 'control'

  const isSaleUIWebEnabled =
    useFeatureFlag('24d_ff_shop_sale_ui_web', false).flagValue === true

  return (
    <MultiItemSlider
      aria-label={title}
      columnClassName="col-sm-5 col-xs-10"
      title={title}
      {...rest}
    >
      {products.map((product, idx) =>
        isSaleUIWebEnabled ? (
          <ProductCardV2
            key={product.gpId}
            {...product}
            position={idx + 1}
            slugSet={null}
            __forcePageLoad={isInPDPRedesignFeature}
            inSaleBadgingFeature={isSaleUIWebEnabled}
            isMobile={isMobile}
            actionButton={
              <ProductCardAction
                product={product}
                originalCartSubTotal={originalCartSubTotal}
                useHeartbeatDS
              />
            }
          />
        ) : (
          <ProductCard
            key={product.gpId}
            {...product}
            merchandisingMessage={null}
            // @ts-ignore
            actionButton={
              <ProductCardAction
                product={product}
                originalCartSubTotal={originalCartSubTotal}
              />
            }
            reactRouterEnabled={false}
          />
        )
      )}
    </MultiItemSlider>
  )
}
